import React, { useState } from 'react';
import styled from 'styled-components';
import MenuSVG from 'resources/img/menu.svg';
import LateralMenu from './LateralMenu';

const Container = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    order: 4;
  }
`;

const Menu = styled.img`
  width: 25px;
  height: 25px;
`;

const ShowMenu = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <>
      <Container onClick={toggle}>
        <Menu src={MenuSVG} alt={'open-menu'} />
      </Container>
      {open && <LateralMenu toggle={toggle} open={open} />}
    </>
  );
};

export default ShowMenu;
