import useI18n from 'i18n/useI18n';
import React, { useContext } from 'react';
import { FilterContext } from '../../../providers/filter';
import { SelectorContainer } from 'pages/home/pages/Reservations/styles';
import { Selector, Label, InputContainer, SelectFormatOptionLabel } from './styles';

const FilterStatus = () => {
  const { translate } = useI18n();
  const { filter, setFilter, options } = useContext(FilterContext);

  return (
    <SelectorContainer style={{ width: '230px'}}>
      <InputContainer className={!!filter ? 'up' : ''}>
        <Selector
          options={options}
          value={filter ?? ''}
          onChange={(value)=>setFilter(value)}
          isSearchable={false}
          defaultMenuIsOpen={false}
          placeholder={''}
          classNamePrefix="react-select"
          formatOptionLabel={SelectFormatOptionLabel}
        />
        <Label>{translate('Filter by')}</Label>
      </InputContainer>
    </SelectorContainer>
  );
};

export default FilterStatus;
