
import { createContext, useEffect, useState } from 'react';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import useDate from 'state/date';
import useReservations from 'state/reservations';

export const FilterContext = createContext();

export const FILTER_DEFAULT_VALUE = 'all';
const FILTER_DEFAULT_LABEL = 'All Status';

const getOptions = (list) => {
  const options = [{label: FILTER_DEFAULT_LABEL, value: FILTER_DEFAULT_VALUE, count: list.length}];
  Object.values(LOCAL_STATUS_CODE).forEach((status)=>{
    options.push({label: status, value: status, count: list.filter(({localStatusCode})=>localStatusCode===status).length});
  });
  return options;
};

export const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState(null);
  const [options, setOptions] = useState([]);
  const [reservations] = useReservations();
  const [date] = useDate();

  useEffect(()=>{
    setOptions(getOptions(reservations.dates[date] ?? []));
  },[reservations.dates, date]);

  useEffect(()=>{
    if(options.length===0) return;
    setFilter(options[0]);
  },[options]);
  

  return <FilterContext.Provider value={{ filter, setFilter, options }}>{children}</FilterContext.Provider>
}
