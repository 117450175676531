import { LOCAL_STATUS_CODE, OPENTABLE_STATUS_CODE, STATUS_CODE, OPENTABLE_ATTENDED_STATUS } from 'resources/constants/status';
import { getTimeUntilExpiration } from './time';

export const getLocalStatus = (reservation) => {
  if (isCancelled(reservation)) return LOCAL_STATUS_CODE.CANCELLED;
  if (needsLink(reservation)) return LOCAL_STATUS_CODE.CREATE;
  if ((isAttendedOpenTableStatus(reservation) && reservation.link.statusCode === STATUS_CODE.CONFIRMED) || reservation.link.statusCode === STATUS_CODE.ATTENDED)
    return LOCAL_STATUS_CODE.ATTENDED;
  if (
    reservation.state === OPENTABLE_STATUS_CODE.NO_SHOW ||
    reservation.link.statusCode === STATUS_CODE.NOT_ATTENDED ||
    reservation.link.statusCode === STATUS_CODE.CHARGED
  )
    return LOCAL_STATUS_CODE.NO_SHOW;

  const expired = isExpired(reservation);
  if (reservation.link.statusCode === STATUS_CODE.PENDING) return expired ? LOCAL_STATUS_CODE.EXPIRED : LOCAL_STATUS_CODE.WAITING;
  if (reservation.link.statusCode === STATUS_CODE.CONFIRMED) return LOCAL_STATUS_CODE.CONFIRMED;
};

export const isCancelled = ({ link, state }) => link?.statusCode === STATUS_CODE.CANCELLED || state === OPENTABLE_STATUS_CODE.CANCELLED;

export const needsLink = (reservation) => shouldBeClickable(reservation) && !reservation.link;

export const shouldBeClickable = (reservation) => {
  return !!reservation.guest;
};

export const isAttendedOpenTableStatus = ({ state }) => OPENTABLE_ATTENDED_STATUS.includes(state);

export const isExpired = (rsv) => {
  const time = getTimeUntilExpiration(rsv);

  return time === 0;
};

export const needsCharge = ({ link, state }) => (link.statusCode === STATUS_CODE.NOT_ATTENDED || (state === OPENTABLE_STATUS_CODE.NO_SHOW && link.statusCode !== STATUS_CODE.CHARGED)) && !link.charge && !link.electedNoCharge;

export const chargePending = ({ link }) => link.statusCode === STATUS_CODE.CHARGED && (!link.charge || link.charge?.statusCode === 'processing');

export const chargeSuccess = ({ link }) => link.statusCode === STATUS_CODE.CHARGED && !!link.charge && link.charge.statusCode === 'succeeded';

export const hasCharge = (link) => !!link.charge;

export const isActionRequired = (reservation) => {
  switch (reservation.localStatusCode) {
    case LOCAL_STATUS_CODE.CREATE:
    case LOCAL_STATUS_CODE.WAITING:
    case LOCAL_STATUS_CODE.EXPIRED:
      return true;
    case LOCAL_STATUS_CODE.NO_SHOW:
      return needsCharge(reservation);
    case LOCAL_STATUS_CODE.CANCELLED:
    case LOCAL_STATUS_CODE.ATTENDED:
    case LOCAL_STATUS_CODE.CONFIRMED:
    default:
      return false;
  }
};