import React, { useState, useEffect } from 'react';
import { DATE_RANGE_TYPES } from 'resources/constants/selectors';
import { Container } from './styles';
import CalendarPicker from 'components/CalendarPicker';
import usePendingCharges from 'state/pendingCharges';
import { getFormattedDate } from 'utils/date';

const DayView = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [charges, dispatcher] = usePendingCharges();

  useEffect(()=>{
    const date = charges.pastDate;
    if (date === null || date.length === 0) return;

    setStartDate(new Date(date + 'T00:00:00')); //If T00:00:00 is not appended to the date string the calendar can be off by one day, quirks of using the native Date() class
  },[charges.pastDate]);

  const onDateChange = (date) => {
    const formattedDate = getFormattedDate(date.toString())
    dispatcher.changeDate({start: formattedDate, end: formattedDate});
  };

  return charges.dateRangeType===DATE_RANGE_TYPES.DAY ? (
    <Container>
      <CalendarPicker
        selected={startDate}
        onChange={onDateChange}
      />
    </Container>
  ) : null;
};

export default DayView;
