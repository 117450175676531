import React, { useContext } from 'react';
import styled from 'styled-components';
import { INPUTS, SECONDARY_DARK, TEXT } from '@etiquette-ui/colors';
import Search from "./Search";
import ViewSelector from "./ViewSelector";
import { SearchContext } from '../../providers/search';
import FilterStatus from './FilterStatus';
import { isSummary } from './utils';
import UpdateButton from './UpdateButton';

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: solid 1px ${INPUTS};
  align-items: center;
  padding: 0px 15px;
  padding-bottom: 10px;
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 10px auto;
    color: ${TEXT};
  }

  @media( min-width: 1001px ){
    padding: 10px 15px;
    flex-wrap: wrap;
  }

  @media (min-width: 768px) and (max-width: 1024px){
    padding-left: 0px;
  }
`;

const FilterStatusContainer = styled.div`
  order: 4;
  padding: 8px 15px;
  max-width: 110%;
  flex: 1;
  background-color: ${SECONDARY_DARK};
  margin: 5px -15px 0px -15px;


  @media( min-width: 768px ){
    order: 2;
    width: auto;
    max-width: auto;
    flex: auto;
    padding: 0px 15px;
    background-color: transparent;
    margin-top: 0px;
    margin-left: 0px;
  }

`;

const CardHeader = ({location, url}) => {
  const { search, setSearch } = useContext(SearchContext);
  return (
    <Header>
      <ViewSelector pathname={location.pathname} url={url}/>
      {!isSummary(location.pathname) && (
        <FilterStatusContainer>
          <FilterStatus />
        </FilterStatusContainer>
      )}
      <Search search={search} onChange={(e) => setSearch(e.target.value)} />
      <UpdateButton pathname={location.pathname}/>
    </Header>
  );
};

export default CardHeader;
