import React from 'react';
import { Container, Icon, Info, AdditionalInfo } from './styles';

const CommonInfoElement = ({ source, data, altData, textColor, action, style }) => {
    return (
      <Container withAction={!!action} style={style}>
        <Icon src={source} />
        <Info color={textColor}>{data}</Info>
        {!!action && action}
        {altData && <AdditionalInfo>{altData}</AdditionalInfo>}
      </Container>
    );
};

export default CommonInfoElement;