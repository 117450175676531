import React from 'react';
import styled from 'styled-components';
import ArrowButton from 'components/Buttons/ArrowButton';
import useI18n from 'i18n/useI18n';
import { IconButtonContainer, IconButtonText, IconButtonIcon } from 'components/Buttons/IconButton';
import { WRONG } from '@etiquette-ui/colors';
import CloseSVG from 'resources/img/close-icon-red.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(IconButtonText)`
  margin-right: 10px;
  color: ${WRONG};
  font-size: 15px;
  font-weight: 600;
`;

const TimerStep = ({ item, action, cancel, currentStep }) => {
  const { translate } = useI18n();
  return currentStep === 0 ? (
    <Container>
      <ArrowButton onClick={action} text={translate('Reset time')} />
      <IconButtonContainer onClick={cancel} style={{ marginLeft: '10px' }} active={true}>
        <Text>{translate('Cancel Reservation')}</Text>
        <IconButtonIcon src={CloseSVG} alt={'Cancel'} />
      </IconButtonContainer>
    </Container>
  ) : null;
};

export default TimerStep;
