import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';

const Create = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.EXPIRED} structure={structure} title={'expired_table_title'} htmlId={'expired-table-container'} />;
};

export default Create;
