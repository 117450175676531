const TYPE = `[NOMENCLATORS]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    SELECT: `${TYPE} SELECT`,
    CONFIRM: `${TYPE} CONFIRM`,
    
    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,
    
    DELETE_START: `${TYPE} DELETE START`,
    DELETE_SUCCESS: `${TYPE} DELETE SUCCESS`,
    DELETE_ERROR: `${TYPE} DELETE ERROR`,

    SUBMIT_START: `${TYPE} SUBMIT START`,
    SUBMIT_SUCCESS: `${TYPE} SUBMIT SUCCESS`,
    SUBMIT_ERROR: `${TYPE} SUBMIT ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;