import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dispatcher from './dispatcher';
import selector from './selector';

const useDate = () => {
    const dispatcher = new Dispatcher(useDispatch());
    const date = useSelector(selector, shallowEqual);

    return [date, dispatcher];
}

export default useDate;