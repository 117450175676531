function getUserId(){
    const userObj = getAuth();

    return userObj ? userObj.email : null;
}

function getAuth() {
    const auth = localStorage.getItem('auth')

    return auth ? JSON.parse(auth) : null;
}


module.exports = {getUserId, getAuth};