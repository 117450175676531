import { DEFAULT_NO_ERROR } from 'state/constants';
import { NOMENCLATORS_FAMILIES } from './constants';
import { confirmStrategy, createSuccesStrategy, deleteStrategy, fetchSuccessStrategy, selectStrategy, submitStartStrategy, submitSuccesStrategy } from './strategies';
import Types from './types';

const INITIAL_STATE = {
  ...Object.fromEntries(Object.keys(NOMENCLATORS_FAMILIES).map((key)=>[NOMENCLATORS_FAMILIES[key],{ list: [], selected: null, confirmed: false }])),
  isFetching: false,
  error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_START: return { ...state, isFetching: true };
    case Types.FETCH_SUCCESS: return fetchSuccessStrategy(action.payload?.family)(action.payload?.data, action.payload.complete, state);
    case Types.FETCH_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };
    
    case Types.DELETE_START: return deleteStrategy(action.payload?.family)(action.payload?.data, state);
    case Types.DELETE_SUCCESS: return { ...state, isFetching: false, error: { ...DEFAULT_NO_ERROR }};
    case Types.DELETE_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };

    case Types.CREATE_START: return { ...state, isFetching: true };
    case Types.CREATE_SUCCESS: return createSuccesStrategy(action.payload?.family)(action.payload?.data, state);;
    case Types.CREATE_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };

    case Types.SELECT: return selectStrategy(action.payload?.family)(action.payload?.data, state);

    case Types.CONFIRM: return confirmStrategy(action.payload?.family)(action.payload?.data, state);

    case Types.SUBMIT_START: return submitStartStrategy(action.payload?.family)(action.payload?.data, state);
    case Types.SUBMIT_SUCCESS: return submitSuccesStrategy(action.payload?.family)(action.payload?.data, state);
    case Types.SUBMIT_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };

    case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

    default: return state;
  }
};

export default reducer;
