import React, { useEffect, useState, useContext } from 'react';
import { getTimeUntilExpiration, formatTime, ALERT_THRESHOLD } from 'utils/time';
import { ClockContext } from 'providers/clock';
import styled from 'styled-components';
import useI8n from 'i18n/useI18n';
import { WRONG } from '@etiquette-ui/colors';

export const Timer = styled.p`
  margin: 0;
  text-align: left;
  font-weight: ${({ hasAlert }) => (hasAlert ? '400' : '700')};
  ${({ hasAlert }) => (hasAlert ? `color: ${WRONG};` : '')}
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 700;
  font-size: 13px;

  @media( min-width: 768px ){
    font-size: 15px;
  }
`;

const TimerComponent = ({ reservation, className, withAlert=true }) => {
  const [time, setTime] = useState(0);
  const [subscribe, unsusbcribe] = useContext(ClockContext);
  const { translateSeconds } = useI8n();

  useEffect(() => {
    subscribe(tick);
    return () => {
      unsusbcribe(tick);
    };
  }, []);  

  const tick = () => {
    setTime(getTimeUntilExpiration(reservation));
  };

  return <Timer className={className} hasAlert={time < ALERT_THRESHOLD && withAlert}>{`${formatTime(time)} ${translateSeconds(time)}`}</Timer>;
};

export default TimerComponent;
