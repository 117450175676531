import { createContext } from "react";

const listeners = new Set();


export const ClockContext = createContext();

export const ClockProvider = ({ children }) => {

    setInterval(() => {
        for (let callback of listeners) {
            callback();
        }
    }, 1000);

    const subscribe = (callback) => {
        listeners.add(callback);
    };
    const unsubscribe = (callback) => {
        listeners.delete(callback);
    } 

    return (
        <ClockContext.Provider value={[subscribe, unsubscribe]}>
            {children}
        </ClockContext.Provider>
    )
}