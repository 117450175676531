import ArrowButton from 'components/Buttons/ArrowButton';
import useI18n from 'i18n/useI18n';
import React from 'react';
import { BoldText } from 'components/Text';
import { OPENTABLE_STATUS_CODE } from 'resources/constants/status';
import styled from 'styled-components';
import OKSVG from 'resources/img/ok-icon.svg';
import { OPENTABLE_CANCEL_URL } from 'resources/constants/urls';
import { OPENTABLE_RID } from 'resources/constants/config';
import { getFormattedDate } from 'utils/date';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const Action = ({ item, globalState }) => {
  const { translate } = useI18n();

  const getBody = () => {
    if (item.state !== OPENTABLE_STATUS_CODE.CANCELLED)
      return (
        <ArrowButton
          onClick={() => {
            window.open(`${OPENTABLE_CANCEL_URL}${OPENTABLE_RID}/front-of-house#/reservations/${getFormattedDate(item.scheduledTime)}`, '_blank').focus();
          }}
          text={globalState.config.translate('Cancel on OpenTable')}
        />
      );
    return <><Icon src={OKSVG}/><BoldText>{translate('Successful Cancellation')}</BoldText></>;
  };

  return <Container>{getBody()}</Container>;
};

export default Action;
