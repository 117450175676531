import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedName, getFormattedPhone } from 'utils/text';
import ViewDetails from '../../cells/ViewDetails';
import LastUpdatedBy from 'components/Table/LastUpdatedBy';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 3,
      header: ({ globalState }) => <Text text={globalState.config.translate('Date and Time')} />,
      body: ({ globalState, item }) => <Text style={{paddingLeft: '5px'}} text={globalState.config.formatDate(item.scheduledTime)} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <Text text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={getFormattedName(item.guest)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text style={{ justifyContent: 'center' }} text={globalState.config.translate('#ppl')} />,
      body: ({ item }) => <Text style={{ justifyContent: 'center' }} text={item.partySize} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <Text text={globalState.config.translate('Phone')} />,
      body: ({ item }) => <Text text={getFormattedPhone(item.guest)} />,
    },
    {
      flex: 3,
      header: ({globalState}) => <Text text={globalState.config.translate('Created by')} />,
      body: ({ item }) => <LastUpdatedBy item={item} field={'createdBy'} />,
    },
    {
      flex: 3,
      header: ({globalState}) => <Text text={globalState.config.translate('Last Change')} />,
      body: ({ item }) => <LastUpdatedBy item={item} />,
    },
    {
      flex: 2,
      header: () => <Text text={''} />,
      body: ViewDetails,
    },
  ],
};

export default structure;

