import styled from 'styled-components';

export const ColumnHours = styled.div`
  width: 100%;
`;

export const ColumnDates = styled.div`
  overflow-x: scroll;

  @media( min-width: 768px ){
    overflow-x: unset;
  }
`;

export const TimeSlotContainerHours = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ededed;
`;

export const TimeSlotContainer = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ededed;
  width: 2000px;
`;

export const TimeSlotRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 68px;
  background: white;
`;

export const Time = styled.p`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0px;
  background-color: white;
  min-width: 40px;
`;

export const TimeAppointmens = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const ScheduleMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  .timeContainer:first-child {
    border-top: 1px solid #ededed;
  }

  position: relative;

  @media( min-width: 768px ){
    overflow: scroll;
  }
`;

export const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: 50px minmax(0, 1fr);
`;
