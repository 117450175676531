import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import styled from 'styled-components';
import { RIGHT_V_LIGHT, WRONG_V_LIGHT, PRIMARY   } from '@etiquette-ui/colors';
import IconButton from 'components/Buttons/IconButton';
import copy from 'copy-to-clipboard';
import { API, STRIPE_DISPUTES_URL } from 'resources/constants/urls';

const Row = styled(RowContainer)`
  background-color: ${({item})=> item.status === 'won' ? `${RIGHT_V_LIGHT}` : `${WRONG_V_LIGHT}`};
`;

const Button = styled(IconButton)`
  margin-left: 0;
  p {
    color: ${PRIMARY };
  }
`;


const structure = {
  row: Row,
  columns: [
    {
      flex: 1,
      header: ({ globalState }) => <Text styles={{ paddingLeft: '10px',}} text={globalState.config.translate('Action Item')}/>,
      body: ({ globalState, item }) => (
        <Button style={{ paddingLeft: '5px' }} onClick={() => {
          window.open(`${STRIPE_DISPUTES_URL}${item.stripeIntent}`, '_blank').focus();
        }} icon={'go-to'} text={globalState.config.translate('Go To Link')} leading={false} />
      ),
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text style={{ paddingLeft: '5px' }} text={globalState.config.translate('Description')} />,
      body: ({ globalState, item }) => (
        <Button
          onClick={() => {
            copy(item.text);
            globalState.config.snacks({ message: 'Copied to clipboard' });
          }}
          icon={'copy-down'}
          text={globalState.config.translate('Copy Data')}
          leading={false}
        />
      ),
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Status')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.translate(item.status)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Date')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.formatDate(item.createdAt)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={item.customerName} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Email')} />,
      body: ({ item }) => <Text text={item.email} />,
    },
    {
      flex: 1,
      header: () => <Text text={'PDF'} />,
      body: ({ globalState, item }) => <Button onClick={() => {
        window.open(`${API}/reservations/admin/dispute/document/${item.id}`, '_blank').focus();
      }} icon={'download'} text={globalState.config.translate('PDF File')} leading={false} />,
    },
  ],
};

export default structure;
