import React from 'react';
import alertTypes from './types';
import ConfirmDialog from './components/ConfirmDialog';
import InfoDialog from './components/InfoDialog';

export const Dialog = ({ open, onSubmit, onClose, type, config }) => {
    switch (type) {
        case alertTypes.CONFIRM:
            return (
                <ConfirmDialog
                    open={open}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    config={config}
                />
            );
        case alertTypes.INFO:
            return (
                <InfoDialog
                    open={open}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    config={config}
                />
            );
        case alertTypes.CUSTOM:
            const CustomBody = config.body;
            const props = config.props ?? {};
            return (
                <CustomBody open={open} onSubmit={onSubmit} onClose={onClose} {...props} />
            );
        default:
            return null;
    }
};
