import React from 'react';
import styled from 'styled-components';
import TimerComponent from 'pages/home/pages/Reservations/components/TimerComponent';
import ArrowButton from 'components/Buttons/ArrowButton';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding-right: 10px;
`;

const Button = styled(ArrowButton)`
  margin-left: 5px;
  max-width: 50%;
  flex: 0 0 50%;
`;

const Timer = styled(TimerComponent)`
  max-width: 50%;
  flex: 0 0 50%;
`;

const TimerStep = ({ item, action, currentStep }) => {
  const { translate } = useI18n();

  return currentStep === 0 ? (
    <Container>
      <Timer reservation={item} hasAlert={true} />
      <Button onClick={action} text={translate('Extend')} />
    </Container>
  ) : null;
};

export default TimerStep;
