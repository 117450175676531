import styled from 'styled-components';
import { Card } from "@etiquette-ui/misc";

const CardContainer = styled(Card)`
  width: 100%;
  height: 85%;
  border-radius: 15px;
  padding: 15px 0px 0px 0px;
  margin: auto;
  margin-top: 15px;
  overflow: hidden;

  @media( min-width: 1001px ){
    width: 95%;
    height: 87%;
    border-radius: 30px;
  }
`;

export default CardContainer;