import React, { useEffect } from 'react';
import CalendarImg from 'resources/img/calendar-icon.svg';
import ClockImg from 'resources/img/clock-icon.svg';
import CustomerImg from 'resources/img/customer-icon.svg';
import MobileImg from 'resources/img/mobile-icon.svg';
import DinerImg from 'resources/img/chair-icon.svg';
import { DISPLAY_DATE_FORMAT, getDisplayTime } from 'utils/date';
import { getFormattedName, getFormattedPhone, getFormattedTable } from 'utils/text';
import useI8n from 'i18n/useI18n';
import CommonInfoElement from './CommonInfoElement';
import Nomenclator from './Nomenclator';
import HotelSVG from 'resources/img/hotel-icon.svg';
import ConciergeSVG from 'resources/img/concierge-icon.svg';
import TypeSVG from 'resources/img/type-icon.svg';
import useNomenclators from 'state/nomenclators';
import { NOMENCLATORS_FAMILIES } from 'state/nomenclators/constants';
import { RESERVATION_HOTEL_NAME } from 'resources/constants/config';
import {isCancelled} from "../../../../../../../utils/status";

const CommonInfo = ({ reservation }) => {
  const { translate, formatDate } = useI8n();
  const [data] = useNomenclators();

  const hasNomenclator = (family, nomenclator) => {
    if(data[family]?.selected?.label === nomenclator) return true;
    if(!!reservation.link?.nomenclators && reservation.link.nomenclators[family]?.name === nomenclator) return true;
    return false;
  }

  return (
    <>
      <CommonInfoElement source={CalendarImg} data={formatDate(reservation.scheduledTime, DISPLAY_DATE_FORMAT)} />
      <CommonInfoElement source={ClockImg} data={getDisplayTime(reservation.scheduledTime)} />
      <CommonInfoElement source={CustomerImg} data={getFormattedName(reservation.guest)} />
      <CommonInfoElement source={MobileImg} data={getFormattedPhone(reservation.guest)} />
      <CommonInfoElement source={DinerImg} data={getFormattedTable(reservation, translate('Table for'))} />
      {
        !isCancelled(reservation) && (
          <Nomenclator reservation={reservation} family={NOMENCLATORS_FAMILIES.RESERVATION_TYPE} familyId={'NOMENCLATORS_IDS.RESERVATION_TYPE'} icon={TypeSVG} label={translate('Reservation Type')} creatable={false} />
        )
      }
      {hasNomenclator(NOMENCLATORS_FAMILIES.RESERVATION_TYPE, RESERVATION_HOTEL_NAME) && (
        <Nomenclator reservation={reservation} family={NOMENCLATORS_FAMILIES.ORIGIN} familyId={'NOMENCLATORS_IDS.ORIGIN'} icon={HotelSVG} label={translate('Add Hotel Tag')} />
      )}
      {hasNomenclator(NOMENCLATORS_FAMILIES.RESERVATION_TYPE, RESERVATION_HOTEL_NAME) && (
        <Nomenclator reservation={reservation} family={NOMENCLATORS_FAMILIES.CONCIERGE} familyId={'NOMENCLATORS_IDS.CONCIERGE'} icon={ConciergeSVG} label={translate('Add Concierge Name')} />
      )}
    </>
  );
};

export default CommonInfo;
