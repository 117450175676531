import { takeEvery, put, all, call, takeLatest } from 'redux-saga/effects';
import { notificationsAPI } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartAsync() {
    try {
        const res = yield notificationsAPI.getAll();
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
    yield takeEvery(Types.FETCH_START, fetchStartAsync);
}


function* readedStartAsync() {
    yield notificationsAPI.readed();
}

function* readedStart() {
    yield takeLatest(Types.READED_START, readedStartAsync)
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(readedStart)
    ]);
}
