import Axios from "axios";
import { getToday } from "utils/date";
import Authorization from './auth';
import { executeCall } from './utils';

const loadListData = async (url, headers, dateStart, dateEnd, numberOfAttempts = 0, error = '') => {
    if( numberOfAttempts < 3 ) {
        try {
            const response = await Axios.get(`${url}/${dateStart}/${dateEnd}`, { headers, timeout: 240000 });
            return response.data;
        } catch( error ){
            numberOfAttempts = numberOfAttempts + 1;
            loadListData(url, headers, dateStart, dateEnd, numberOfAttempts, error);
        }
    } else {
        throw error;
    }
}
    

const reservations = (domain, resource, withAuth = true) => {
    const url = `${domain}/${resource}`;

    const headers = withAuth ? Authorization : {};

    return {
        list: (start, end) => {
            const dateStart = start ?? getToday();
            const dateEnd = end ?? dateStart;

            return loadListData(url, headers, dateStart, dateEnd);
        },
        create: (data) => executeCall(() => Axios.post(url, data, { headers, timeout: 240000 })),

        update: (data) => executeCall(() => Axios.patch(url, data, { headers, timeout: 240000 })),

        nomenclators: (data) => executeCall(() => Axios.patch(`${url}/nomenclators`, data, { headers, timeout: 240000 })),

        charge: (data) => {
            const customUrl = `${url}/charge`;

            return executeCall(() => Axios.post(customUrl, data, { headers, timeout: 240000 }))
        },

        loadOne: code => {
            const customUrl = `${url}/load-reservation/${code}`;

            return executeCall(() => Axios.get(customUrl, { headers, timeout: 240000 }))
        }

    };
};

export default reservations;