import { STATUS_CODE } from 'resources/constants/status';
import { DEFAULT_NO_ERROR } from 'state/constants';
import { getLocalStatus } from 'utils/status';
import Types from './types';

const INITIAL_STATE = {
    dates: {},
    isFetching: false,
    fetchingData:[],
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START:
        case Types.IS_FETCHING: return { ...state, isFetching: true}

        case Types.STOP_FETCHING: return { ...state, isFetching: false }

        case Types.FETCH_SUCCESS: return { ...state, dates: { ...state.dates, ...action.payload }, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }

        case Types.UPDATE_ALL: return { ...state, dates: action.payload }

        case Types.UPDATE_LINK_START:
        case Types.UPDATE_CHARGE_START: return { ...state, isFetching: true }
        case Types.UPDATE_LINK_SUCCESS: {
            const dates = { ...state.dates };
            const list = [...dates[action.payload.date]];
            const item = list.find(({ link }) => link?.code === action.payload.code);
            const index = list.indexOf(item);
            item.link = { ...item.link, ...action.payload };
            item.localStatusCode = getLocalStatus(item);
            if (index > -1) {
                list[index] = item;
                dates[action.payload.date] = list;
            }
            return { ...state, dates, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        }
        case Types.UPDATE_LINK_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }

        case Types.CREATE_LINK_START: return { ...state, isFetching: true, fetchingData: [...state.fetchingData, action.payload.reservation.id] }
        case Types.CREATE_LINK_SUCCESS: {
            const reservationId = action.payload;
            const filteredFetchingData = state.fetchingData.filter((id) => id !== reservationId);
            return { ...state, isFetching: false, fetchingData: filteredFetchingData, error: { ...DEFAULT_NO_ERROR } }
        }
        case Types.CREATE_LINK_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }

        case Types.CHARGE_START: return { ...state, isFetching: true }
        case Types.CHARGE_SUCCESS: {
            const dates = { ...state.dates };
            const item = Object.values(dates).reduce((acc, curr) => [...acc, ...curr], []).find(({ link }) => link?.code === action.payload.code);

            if (!item?.link) return { ...state, isFetching: false, error: { timestamp: Date.now(), message: 'Internal Error' } };

            item.link.statusCode = STATUS_CODE.CHARGED;
            item.link.charge = !!action.payload.charge ? { ...action.payload.charge } : false;
            item.localStatusCode = getLocalStatus(item);

            const list = [...dates[item.link.date]];
            const index = list.indexOf(item);
            if (index > -1) {
                list[index] = item;
                dates[item.link.date] = list;
            }
            return { ...state, dates, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        }
        case Types.CHARGE_ERROR: {
            const dates = { ...state.dates };
            const item = Object.values(dates).reduce((acc, curr) => [...acc, ...curr], []).find(({ link }) => link?.code === action.payload.code);

            if (!item?.link) return { ...state, isFetching: false, error: { timestamp: Date.now(), message: 'Internal Error' } };

            item.link.statusCode = STATUS_CODE.CHARGED;
            item.link.charge = !!action.payload.charge ? { ...action.payload.charge } : false;
            item.localStatusCode = getLocalStatus(item);

            const list = [...dates[item.link.date]];
            const index = list.indexOf(item);
            if (index > -1) {
                list[index] = item;
                dates[item.link.date] = list;
            }

            return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload.error } }
        }

        case Types.CANCEL_START: return { ...state, isFetching: true }
        case Types.CANCEL_SUCCESS: {
            const dates = { ...state.dates };
            const item = Object.values(dates).reduce((acc, curr) => [...acc, ...curr], []).find(({ link }) => link?.code === action.payload);

            if (!item) return { ...state };
            item.link.statusCode = STATUS_CODE.CANCELLED;
            item.localStatusCode = getLocalStatus(item);

            const list = [...dates[item.link.date]];
            const index = list.indexOf(item);
            if (index > -1) {
                list[index] = item;
                dates[item.link.date] = list;
            }

            return { ...state, dates, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        }
        case Types.CANCEL_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }


        case Types.FETCH_LOADONE_SUCCESS: {
            const key = action.payload.scheduledTime.substr(0, 10);
            const newDates = { ...state.dates };
            const item = action.payload
            item.localStatusCode = getLocalStatus(item);


            if (!newDates[key]) {
                newDates[key] = []
            }
            newDates[key].push(item);

            return { ...state, dates: newDates };
        }

        case Types.FETCH_LOADONE_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }

        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
}

export default reducer