import { LOCAL_STATUS_CODE } from "resources/constants/status"

const EN = {
    "Internal Server Error": "Internal Server Error",
    "Please report this to the dev team.": "Please report this to the dev team.",
    "OK": "OK",
    "Go Back": "Go Back",
    "Cancel": "Cancel",

    "SLOW_CONNECTION":"This is taking longer than usual, please DON'T refresh the page to finish loading the info.",
    
    "Reservations": "Reservations",
    "reservations": "reservations",
    "Search": "Search",
    "Expired": "Expired",
    "Pending": "Pending",
    "Cancelled": "Cancelled",
    "Cancelled at link": "Cancelled at link",
    "Table for": "Table for",
    "Add Hotel Tag": "Add Hotel Tag",
    "Add expiration time": "Add expiration time",
    "Add more expiration time": "Add more expiration time",
    "Added time": "Added time",
    "Update customer link": "Update customer link",
    "Copy": "Copy",
    "Close": "Close",
    "Update Data": "Update Data",
    "Logout": "Log Out",
    "Generate customer link": "Generate customer link",
    "Expires in": "Expires in",
    "mins.": "mins.",
    "seconds": "seconds",
    "Link expiration time": "Link expiration time",
    "Minutes": "Minutes",
    "Hours": "Hours",
    "Days": "Days",
    "Select": "Select",
    "Add": "Add",
    "ago": "ago",
    "Add Concierge Name": "Add Concierge Name",
    "hours": "hours",
    "Attended": "Attended",
    "Confirmed with Card": "Confirmed with Card",
    "Not Attended": "Not Attended",
    "Has the customer attended?": "Has the customer attended?",
    "Customer has not attended": "Customer has not attended",
    "Customer has attended": "Customer has attended",
    "Yes": "Yes",
    "No": "No",
    "Change": "Change",
    "Add Charge": "Add Charge",
    "charged": "charged",
    "Reservation Type": "Reservation Type",
    "Client Type": "Client Type",
    "Edit": "Edit",
    "Wating confirmation": "Wating confirmation",
    "Cancel in OT": "Cancel in OpenTable",
    "Attend?": "Attend?",
    "Call to Customer": "Call to Customer",
    "Create Link": "Create Link",
    "Elected No Charge": "Elected No Charge",

    "All Status": "All Status",
    [LOCAL_STATUS_CODE.CREATE]: "New Reservation",
    [LOCAL_STATUS_CODE.WAITING]: "Waiting For Card",
    [LOCAL_STATUS_CODE.CANCELLED]: "Cancelled",
    [LOCAL_STATUS_CODE.EXPIRED]: "Expired Link",
    [LOCAL_STATUS_CODE.CONFIRMED]: "Confirmed with Card",
    [LOCAL_STATUS_CODE.NO_SHOW]: "No Show",
    [LOCAL_STATUS_CODE.ATTENDED]: "Attended",
    "Cancelled Reservation": "Cancelled Reservation",

    "Notifications": "Notifications",
    "Pending Charges": "Pending Charges",
    "View by": "View by",
    "Filter by": "Filter by",
    "Back": "Back",
    "Reservation Summary": "Reservation Summary",
    "Export report": "Export Activity",
    "Charges": "Charges",
    "Apply All Charges": "Apply All Charges",

    "create_link_table_title": "Create link for customers to complete their information.",
    "waiting_table_title": "See below for customers more times to give credit card.",
    "expired_table_title": "1. Contact customer and ask if they need more time and reset time.  2. If you can’t reach them cancel the reservation in Opentable.",
    "no_show_table_title": "Verify with manager if you should charge, then charge.",
    "confirmed_table_title": "15 minutes before reservation time, you can to mark if customer showed or no showed.",
    "reservations pending to apply charge": " reservations pending to apply charge.",

    "Action Item": "Action Item",
    "Date": "Date",
    "Time": "Time",
    "Date and Time": "Date and Time",
    "Last, First Name": "Last, First Name",
    "#ppl": "#ppl",
    "Phone": "Phone",
    "Time to expiration": "Time to expiration",
    "Extend": "Extend",
    "Update": "Update",
    "View Details": "View Details",
    "Expired Ago": "Expired Ago",
    "Reset time or Cancel": "Reset time or Cancel",
    "Reset time": "Reset time",
    "Call Customer": "Call Customer",
    "Cancel Reservation": "Cancel Reservation",
    "Charge/Status": "Charge/ Status",
    "Successful Charge": "Successful Charge",
    "Charge Rejected": "Charge Rejected",
    "Charge Pending": "Charge Pending",
    "Charge Completed": "Charge Completed",
    "Not charged yet": "Not charged yet",
    "Charged x": "Charged %charge%",
    "Successful Cancellation": "Successful Cancellation",
    "Cancel on OpenTable": "Cancel on OpenTable",
    "Does the client attend?": "Did the client attend?",
    "Total": "Total",
    "Rejected": "Rejected",
    "Collected": "Collected",
    "Status": "Status",
    "Save": "Save",
    "Last Attempt": "Last Attempt",
    "Manager Approved No Charge": "Manager Approved No Charge",
    "Manager approved no charge": "Manager approved no charge",
    "Manager": "Manager",
    "Hostess": "Hostess",
    "Host": "Host",
    "Reason": "Reason",
    "Approved No Charge": "Approved No Charge",
    "Add customer charge.": "Add customer charge.",
    "Apply Charge": "Apply Charge",
    "Created by": "Created by",
    "Last Change": "Last Change",
    "Time to cancel": "Time to cancel",

    "succeeded": "Succeeded",
    "requires_action": "Required Action",
    "processing": "Processing",
    "payment_failed": "Payment Failed",
    "canceled": "Canceled",

    "warning_needs_response":"Warning Needs Response",
    "needs_response":"Needs Response",
    "warning_under_review":"Warning Under Review",
    "under_review":"Under Review",
    "warning_closed":"Warning Closed",
    "charge_refunded":"Charge Refunded",
    "won":"Won",
    "lost":"Lost",

    "Client has cancelled.": "Client %last_name%, %first_name% has cancelled.",
    "Cancelled reservation": "Cancelled reservation",
    "Confirmed reservation": "Confirmed reservation",
    "Updated reservation": "Updated reservation",
    "Go to OpenTable to cancel.": "Go to OpenTable to cancel.",

    "day": "Day",
    "month": "Month",
    "range": "Range",

    "Disputes": "Disputes",
    "Description": "Description",
    "Email": "Email",
    "Go To Link": "Go To Link",
    "Copy Data": "Copy Data",
    "PDF File": "PDF File",

    "Special Client": "Special Client",
    "Owner approval": "Owner approval",
    "Arrangement with Client": "Arrangement with Client",

    "CHARGE_SUCCEEDED_TITLE": "Charge succeeded",
    "CHARGE_SUCCEEDED_MSG": "Charge was succesfully applied with amount of : %amount%",
    "CHARGE_REQUERIES_ACTION_TITLE": "Charge requieres action",
    "CHARGE_REQUERIES_ACTION_MSG": "Charge didn't pass, requires further actions",
    "CHARGE_PROCESSING_TITLE": "Charge processing",
    "CHARGE_PROCESSING_MSG": "Charge is being proccesed",
    "CHARGE_FAILED_TITLE": "Charge failed",
    "CHARGE_FAILED_MSG": 'Charge to client %firstName% %lastName% failed to with error: " %errorMsg% "',
    "CHARGE_CANCELLED_TITLE": "Charge cancelled",
    "CHARGE_CANCELEED_MSG": "The charge was cancelled by stripe",
    "CHARGE_CREATE_TITLE": "New Charge",
    "CHARGE_CREATE_MSG": "A new charge was created on the payment plataform.",

    "CHARGE_WARNING_NEEDS_RESPONSE_TITLE": "Charge needs attention",
    "CHARGE_WARNING_NEEDS_RESPONSE_MSG": "The charge needs to be responded by the team.",
    "CHARGE_WARNING_UNDER_REVIEW_TITLE": "Dispute updated to under review",
    "CHARGE_WARNING_CLOSED_TITLE": "Dispute closed",
    "CHARGE_CHARGE_REFUNDED_TITLE": "Charge refunded because of dispute",
    "CHARGE_WON_TITLE": "Dispute WON",
    "CHARGE_LOST_TITLE": "Dispute LOST",
    "CHARGE_NO_ACTION_MSG": "No action required by the team.",


    "RESERVATION_CREATED_TITLE": "Created reservation",
    "RESERVATION_CREATED_MSG": "New reservation was created for client %firstName% %lastName%",
    "RESERVATION_CANCELLED_TITLE": "Cancelled reservation",
    "RESERVATION_CANCELLED_MSG": "The client %firstName% %lastName% canceled the reservation. Please go to OpenTable to cancel.",
    "RESERVATION_UPDATE_TITLE": "Updated reservation",
    "RESERVATION_UPDATE_MSG": "Reservation was updated.",
    "RESERVATION_CHECKIN_TITLE": "Confirmed reservation",
    "RESERVATION_CHECKIN_MSG": "Client %firstName% %lastName% has confirmed.",
    "VIEW_RESERVATION": "View reservation",
    "View Disputes": "View Disputes",

    "Reservations pending to cancel": "Reservations pending to cancel",
    "Please cancel all these reservations in Opentable.": "Please cancel all these reservations in Opentable.",
    "The link has been copied.": "The link has been copied.",

    "Unauthorized": "Unauthorized",
    "You don't have access to this app": "You don't have access to this app",

    "Link Expired": "Link Expired",
    "Review expired links and contact customer if required": "Review expired links and contact customer if required",
    "Whats app message": "In this link you can read the booking policies and confirm the reservation you requested by entering the required data. The link expires in %time%\n" +
      "Any doubt we're happy to help you.\n" +
      "Quince Rooftop.\n" +
      "%linkURL%",
    "Generic error": "There was an error in the request, please refresh the page"
}

export default EN;
