import React from 'react';
import { INPUTS, TEXT } from '@etiquette-ui/colors';
import styled from 'styled-components';
import TimeIcon from 'resources/img/expiration-icon.svg';
import Selector from '../Selector';

const InputContainer = styled.div`
    display: grid;
    grid-template-columns: ${({mini}) => mini ? '1fr 3fr' : '20px 5fr 3fr'};
    grid-column-gap: 5px;
    justify-items: start;
    padding: 10px;
    background-color: ${INPUTS};
    border-radius: 8px;
    align-items: center;

    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
    }
`;

const Icon = styled.img`
    width: 20px;
    height: auto;
`;

const StyledInput = styled.input`
    border: none;
    background-color: transparent;
    color: ${TEXT};
    font-weight: 500;
    font-size: 1.125rem;
    width: 100%;
    min-width: 15px;
    &:focus {
        outline: unset;
    }
    ::placeholder {
        opacity: 0.3;
    }
`;

const TimeInput = ({ onChange, value, style, selectorValue, onChangeSelector, selectorOptions, className, mini=false }) => {
    return (
        <InputContainer style={style} className={className} mini={mini}>
            {!mini && <Icon src={TimeIcon} />}
            <StyledInput type="number" onChange={onChange} value={value} />
            <Selector selected={selectorValue} handleOptions={onChangeSelector} options={selectorOptions} />
        </InputContainer>
    );
};

export default TimeInput;