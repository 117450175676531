import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useI18n from 'i18n/useI18n';
import { Nav, ItemContainer, Item, IndicatorContainer, Indicator } from './styles';
import isCurrent from './isCurrent';
import Icon from '@etiquette-ui/icons';

const Tabs = ({ routes = [], pathIndex, current, className }) => {
  const { translate } = useI18n();

  return (
    <Nav className={className}>
      {routes.map(({ path, title, icon }, i) => (
        <ItemContainer key={i}>
          <Item>
            <Link to={path} className={isCurrent(path, current, pathIndex) ? 'current' : ''}>
              <div className="icon">
                <Icon icon={icon} styles={{ line:{stroke: '#fff', fill: 'none', strokeWidth:'1.7px'} }} />
              </div>
              {translate(title)}
            </Link>
            <IndicatorContainer>{isCurrent(path, current, pathIndex) && <Indicator />}</IndicatorContainer>
          </Item>
        </ItemContainer>
      ))}
    </Nav>
  );
};

export default Tabs;
