
import { createContext, useState } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState('');

  function resetSearch() {
    setSearch('');
  }

  return <SearchContext.Provider value={{ search, setSearch, resetSearch }}>{children}</SearchContext.Provider>
}
