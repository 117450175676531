import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import GenerateLink from "./GenerateLink";
import InfoLink from "./InfoLink";
import Attended from "./Attended";

export const getAlertBody = ({localStatusCode}) => {
    switch(localStatusCode){
      case LOCAL_STATUS_CODE.ATTENDED:
      case LOCAL_STATUS_CODE.NO_SHOW:
      case LOCAL_STATUS_CODE.CONFIRMED:
        return Attended;
      case LOCAL_STATUS_CODE.CREATE:
      case LOCAL_STATUS_CODE.EXPIRED:
      case LOCAL_STATUS_CODE.WAITING:
        return GenerateLink;
      default:
        return InfoLink; 
    }
}