import React from 'react';
import styled from 'styled-components';
import TypeSelector from './TypeSelector';
import DayView from './DayView';
import RangeView from './Range';
import MonthView from './MonthView';
import { Card } from "@etiquette-ui/misc";

const Container = styled(Card)`
  width: 350px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 5;
  display: grid;
  grid-template-rows: 45px 1fr;
  grid-gap: 5px;
`;

const CalendarView = () => {

  return (
    <Container>
      <TypeSelector />
      <DayView />
      <MonthView />
      <RangeView />
    </Container>
  );
};

export default CalendarView;
