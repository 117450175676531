import styled from 'styled-components';

const Card = styled.div`
    box-shadow: 0px 8px 24px #0332693D;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : 'white'};
    padding: ${({ padding }) => (padding ? padding : '')};
    margin: ${({ margin }) => (margin ? margin : '')};
`;

export default Card;
