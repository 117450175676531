import DEFAULT_NOTIFICATION, { extractSubAction } from './default';

const process = (notification, translate, goToLink) => {
  const type = extractSubAction(notification.type);

  const goToDisputes = () => ({
    text: translate('View Disputes'),
    callback: () => goToLink('/disputes'),
  });

  switch (type) {
    case 'WARNING_NEEDS_RESPONSE':
    case 'NEEDS_RESPONSE':
      return {
        ...warningNeedsResponse(notification, translate),
        action: goToDisputes(),
      };
    case 'WARNING_UNDER_REVIEW':
    case 'UNDER_REVIEW':
      return {
        ...noActionNotification(notification, translate),
        title: translate('CHARGE_WARNING_UNDER_REVIEW_TITLE'),
        action: goToDisputes(),
      };
    case 'WARNING_CLOSED':
      return {
        ...noActionNotification(notification, translate),
        title: translate('CHARGE_WARNING_CLOSED_TITLE'),
        action: goToDisputes(),
      };
    case 'CHARGE_REFUNDED':
      return {
        ...noActionNotification(notification, translate),
        title: translate('CHARGE_CHARGE_REFUNDED_TITLE'),
        action: goToDisputes(),
      };
    case 'WON':
      return {
        ...noActionNotification(notification, translate),
        title: translate('CHARGE_WON_TITLE'),
        action: goToDisputes(),
      };
    case 'LOST':
      return {
        ...noActionNotification(notification, translate),
        title: translate('CHARGE_LOST_TITLE'),
        action: goToDisputes(),
      };

    default:
      return DEFAULT_NOTIFICATION(notification);
  }
};

function warningNeedsResponse(notification, translate) {
  return {
    title: translate('CHARGE_WARNING_NEEDS_RESPONSE_TITLE'),
    message: translate('CHARGE_WARNING_NEEDS_RESPONSE_MSG'),
    isRead: notification.isRead,
  };
}

function noActionNotification(notification, translate) {
  return {
    message: translate('CHARGE_NO_ACTION_MSG'),
    isRead: notification.isRead,
  };
}

export default process;
