import { combineReducers } from 'redux';
import reservations from './reservations/reducer';
import date from './date/reducer';
import highlighted  from './highlighted/reducer';
import nomenclators from './nomenclators/reducer';
import notifications from './notifications/reducer';
import pendingCharges from './pendingCharges/reducer';
import disputes from './disputes/reducer';
import users from './users/reducer';

const rootReducer = combineReducers({
  reservations,
  date,
  highlighted,
  nomenclators,
  notifications,
  pendingCharges,
  disputes,
  users,
});

export default rootReducer;
