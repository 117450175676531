import React, { useEffect, useState } from 'react';
import useDate from 'state/date';
import { getFormattedDate } from 'utils/date';
import CalendarPicker from 'components/CalendarPicker';
import useHighlightedDates from 'state/highlighted';
import styled from 'styled-components';

const FullSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const Calendar = () => {
  const [date, dispatcher] = useDate();
  const [highlightedDates, highlightedDispatcher] = useHighlightedDates();
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (date === null || date.length === 0) return;
    setStartDate(new Date(date + 'T00:00:00')); //If T00:00:00 is not appended to the date string the calendar can be off by one day, quirks of using the native Date() class
  }, [date]);

  const onDateChange = (date) => {
    dispatcher.changeDate(getFormattedDate(date.toString()));
  };

  const onMonthChange = (date) => {
    highlightedDispatcher.changeDisplayedDate(date.toString());
    highlightedDispatcher.fetchStart();
  };

  return (
    <FullSection>
      <CalendarPicker
        selected={startDate}
        onChange={onDateChange}
        onMonthChange={onMonthChange}
        highlighted={highlightedDates.list}
        loading={highlightedDates.isFetching}
      />
    </FullSection>
  );
};

export default Calendar;
