export const STATUS_CODE = {
  CONFIRMED: 'confirmed',
  NOT_ATTENDED: 'hasnotattended',
  ATTENDED: 'hasattended',
  CHARGED: 'charged',
  CANCELLED: 'cancelled',
  PENDING: 'pending',
};

export const OPENTABLE_STATUS_CODE = {
  NOT_CONFIRMED: 'NotConfirmed',
  LEFT_MESSAGE: 'LeftMessage',
  CONFIRMED: 'Confirmed',
  LATE: 'Late',
  PARTIALLY_ARRIVED: 'PartiallyArrived',
  ALL_ARRIVED: 'AllArrived',
  PARTIALLY_SEATED: 'PartiallySeated',
  SEATED: 'Seated',
  APPETIZER: 'Appetizer',
  ENTREE: 'Entree',
  DESSERT: 'Dessert',
  CHECK_DROPPED: 'CheckDropped',
  PAID: 'Paid',
  BUS_TABLE: 'BusTable',
  DONE: 'Done',
  NO_SHOW: 'NoShow',
  CANCELLED: 'Cancelled',
  ASSUMED_SEATED: 'AssumedSeated',
  ASSUMED_DONE: 'AssumedDone',
};

export const OPENTABLE_ATTENDED_STATUS = [
  'Late',
  'PartiallyArrived',
  'AllArrived',
  'PartiallySeated',
  'Seated',
  'Appetizer',
  'Entree',
  'Dessert',
  'CheckDropped',
  'Paid',
  'AssumedSeated',
  'Done',
  'AssumedDone',
];

export const LOCAL_STATUS_CODE = {
  CREATE: 'create',
  WAITING: 'waiting',
  EXPIRED: 'expired',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  NO_SHOW: 'noshow',
  ATTENDED: 'attended',
};
