import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = (payload) => this.dispatch({ type: Types.FETCH_START, payload });
    fetchSuccess = ({ payload }) => this.dispatch({ type: Types.FETCH_SUCCESS, payload });
    fetchFailure = ({ payload }) => this.dispatch({ type: Types.FETCH_ERROR, payload });

    changeDisplayedDate = (payload) => this.dispatch({ type: Types.CHANGE_DISPLAYED_DATE, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}