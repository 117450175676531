import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedName } from 'utils/text';
import Action from './cells/Action';
import LastUpdatedBy from 'components/Table/LastUpdatedBy';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 4,
      header: ({ globalState }) => <Text text={globalState.config.translate('Action Item')} />,
      body: Action
    },
    {
      flex: 3,
      header: ({ globalState }) => <Text text={globalState.config.translate('Date and Time')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.formatDate(item.scheduledTime)} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <Text text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={getFormattedName(item.guest)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text style={{ justifyContent: 'center' }} text={globalState.config.translate('#ppl')} />,
      body: ({ item }) => <Text style={{ justifyContent: 'center' }} text={item.partySize} />,
    },
    {
      flex: 3,
      header: ({globalState}) => <Text text={globalState.config.translate('Host')} />,
      body: LastUpdatedBy,
    },
  ],
};

export default structure;

