export default class Utils {
    constructor(state, dispatcher) {
        this.state = state;
        this.dispatcher = dispatcher;
    }

    findOne(id) {
        return Object.values(this.state.dates).reduce((acc, curr) => acc.concat(curr), []).find(r => r.id === id)
    }

    findOneByCode(code){
        return Object.values(this.state.dates).reduce((acc, curr) => acc.concat(curr), []).find(r => r.link?.code === code)
    }
}