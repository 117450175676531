import { NOMENCLATORS_FAMILIES } from "./constants";
import { cloneState, resetNomenclatorError, resetNomenclatorsKeys } from "./utils";

export const selectStrategy = (family) => {

    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorsKeys(newState, ['selected', 'confirmed'], [NOMENCLATORS_FAMILIES.RESERVATION_TYPE]);
            newState[family].selected = data;
            newState[family].confirmed = false;
            return newState;
        };
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            newState[family].selected = data;
            newState[family].confirmed = false;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorsKeys(newState, ['selected']);
            return newState;
        };
    }
}

export const confirmStrategy = (family) => {

    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            newState[family].confirmed = data;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorsKeys(newState, ['confirmed']);
            return newState;
        };
    }
}

export const submitStartStrategy = (family) => {

    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            newState[family].confirmed = true;
            newState.isFetching = true;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorsKeys(newState, ['confirmed']);
            return newState;
        };
    }
}

export const fetchSuccessStrategy = (family) => {

    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, complete, state) => {
            const newState = cloneState(state);
            resetNomenclatorError(state);
            newState.isFetching = !complete;
            newState[family].list = data;
            return newState;
        };
        default: return (data, complete, state) => {
            const newState = cloneState(state);
            return newState;
        };
    }
}

export const deleteStrategy = (family) => {
    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorError(state);
            newState.isFetching = true;

            const list = [...state[family].list];
            const item = list.find((it)=>it.id==data.id);
            const index = list.indexOf(item);
            if (index > -1) {
              list.splice(index, 1);
            }

            newState[family].list = list;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            return newState;
        };
    }
}

export const createSuccesStrategy = (family) => {
    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorError(state);
            newState.isFetching = false;

            const list = [...state[family].list];
            list.push(data);
            const selected = { value: data.id, label: data.name };

            newState[family].list = list;
            newState[family].selected = selected;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            return newState;
        };
    }
}

export const submitSuccesStrategy = (family) => {
    switch(family) {
        case NOMENCLATORS_FAMILIES.RESERVATION_TYPE:
        case NOMENCLATORS_FAMILIES.CONCIERGE:
        case NOMENCLATORS_FAMILIES.ORIGIN: return (data, state) => {
            const newState = cloneState(state);
            resetNomenclatorError(state);
            newState[family].selected = null;
            newState[family].confirmed = false;
            newState.isFetching = false;
            return newState;
        };
        default: return (data, state) => {
            const newState = cloneState(state);
            return newState;
        };
    }
}