import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    changeDate = (date) => {
        this.dispatch({ type: Types.DATE_WILL_CHANGE, payload: date });
    };
}