import React from 'react';
import { needsLink } from 'utils/status';
import NomenclatorSelector, { NomenclatorSelectorEdit } from './NomenclatorSelector';

const Type = ({ reservation, family, icon, creatable, label }) => {
    return needsLink(reservation) ? (
        <NomenclatorSelector family={family} title={label} icon={icon} creatable={creatable} />
    ) : (
        <NomenclatorSelectorEdit reservation={reservation} family={family} title={label} icon={icon} creatable={creatable} />
    );
};

export default Type;
