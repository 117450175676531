import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: Types.FETCH_START });

    createStart = (payload) => this.dispatch({ type: Types.CREATE_START, payload });

    deleteStart = (payload) => this.dispatch({ type: Types.DELETE_START, payload });

    select = (payload) => this.dispatch({ type: Types.SELECT, payload });

    confirm = (payload) => this.dispatch({ type: Types.CONFIRM, payload });

    submitStart = (payload) => this.dispatch({ type: Types.SUBMIT_START, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}