import React from 'react';
import BaseDialog from './BaseDialog';
import { TransparentButton, ColorButton } from 'components/Buttons';
import styled from 'styled-components';
import { Text } from 'components/Text';

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const Actions = ({ onClose, closeText, onSubmit, submitText }) => {
  return (
    <Section>
      <TransparentButton onClick={onClose}>{closeText}</TransparentButton>

      <ColorButton onClick={onSubmit}>{submitText}</ColorButton>
    </Section>
  );
};

const InfoDialog = ({ open, onClose, onSubmit, config }) => {

  return (
    <BaseDialog
      open={open}
      title={config.title}
      header={config.header}
      onClose={onClose}
      dismissible={true}
      isResponsive={true}
      actions={<Actions onClose={onClose} closeText={config.closeText} onSubmit={onSubmit} submitText={config.submitText} />}
    >
      <Text style={{marginTop: '20px'}}>{config.body}</Text>
    </BaseDialog>
  );
};

export default InfoDialog;