import React, { useContext } from 'react';
import ShowDate from './components/ShowDate';
import ScheduleTable from './components/ScheduleTable';
import styled from 'styled-components';
import { SearchContext } from '../../providers/search';

const ScheduleSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px minmax(0, 1fr);
  padding: 0px;
  padding-left: 15px;

  @media( min-width: 768px ) {
    padding: 0 36px;
  }
`;

const Schedule = () => {
  const { search } = useContext(SearchContext);
  return (
    <ScheduleSectionContainer>
      <ShowDate />
      <ScheduleTable search={search} />
    </ScheduleSectionContainer>
  );
};

export default Schedule;
