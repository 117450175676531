import React, { useEffect } from 'react';
import IconButton from 'components/Buttons/IconButton';
import useI8n from 'i18n/useI18n';
import { useState } from 'react';
import styled from 'styled-components';
import Card from 'containers/Card';
import useNotifications from 'state/notifications';
import { Text } from 'components/Text';
import Notification from './Notification';
import process from './processor';
import { PRIMARY } from "@etiquette-ui/colors";
import { useAlert } from 'alert';
import hookAlertTypes from 'alert/types';
import { getAlertBody } from 'pages/home/pages/Reservations/alert/utils';
import useReservations from 'state/reservations';
import { useHistory } from 'react-router-dom';

const Notifications = () => {
  const { translate } = useI8n();
  const [open, setOpen] = useState(false);
  const [data, dispatcher] = useNotifications();
  const [reservations, reservationsDispatcher, utils] = useReservations();
  const alert = useAlert();
  const history = useHistory();

  const [code, setCode] = useState(false);

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  const handleOpen = () => {
    setOpen(!open);
    if (!open && !data.readed) {
      dispatcher.setReaded(!open);
      dispatcher.fetchStart();
    }
  };

  useEffect(()=>{open && dispatcher.setAllNotificationsReaded();}, [open]);

  useEffect(() => {
    if (!code) return;

    setCode(false);
    const item = utils.findOneByCode(code);
    showAlert(item);
  }, [reservations]);

  const showReservationDialog = (code) => {
    const item = utils.findOneByCode(code);

    if (!item) {
      reservationsDispatcher.loadOne(code);
      setCode(code);
      return;
    }

    showAlert(item);
  };

  const showAlert = (item) => {
    if (!item) return;

    alert({
      type: hookAlertTypes.CUSTOM,
      config: {
        props: {
          id: item.id,
        },
        body: getAlertBody(item),
      },
    }).catch(() => {});
  };

  const goToLink = (url) => {
    history.push(url);
  };

  return (
    <Container>
      <Readed visible={!data.readed} />
      <IconButton onClick={handleOpen} icon={'notification'} isMenuItem={true} text={translate('Notifications')} />
      {open && (
        <NotificationsTray>
          {data.list.length === 0 && <Text style={{ textAlign: 'center' }}>{translate('No notifications')}</Text>}
          {data.list.map((notif, idx) => (
            <Notification key={'notification-' + idx} {...process(notif, translate, showReservationDialog, goToLink)} />
          ))}
        </NotificationsTray>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  order: 2;

  @media( min-width: 768px ){
    order: 1;
  }
`;

const NotificationsTray = styled(Card)`
  width: 300px;
  max-height: 370px;
  overflow: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 30px;
  left: auto;
  right: -100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  z-index: 10;

  @media( min-width: 1001px ){
    right: auto;
    left: 0;
  }
`;

const Readed = styled.div`
  position: absolute;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  background-color: ${PRIMARY};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: -5px;
  left: 20px;

  @media( min-width: 768px ){
    width: 10px;
    height: 10px;
    top: -8px;
    left: 22px;
  }
`;

export default Notifications;
