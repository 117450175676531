import React from 'react';
import styled from 'styled-components';
import { PRIMARY, SECONDARY_DARK , TEXT_PLACEHOLDER, WRONG  } from "@etiquette-ui/colors";
import Card from 'containers/Card';

export const BaseButton = styled.button`
  width: 100%;
  padding: 15px 5px;
  border: 1px solid ${({ color }) => (color ? color : PRIMARY)};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:focus {
    outline: 1px solid ${({ color }) => (color ? color : PRIMARY)};
  }
`;

export const ColorButton = styled(BaseButton)`
  color: white;
  background-color: ${({ color }) => (color ? color : PRIMARY)};
  &:hover {
    background-color: white;
    color: ${({ color }) => (color ? color : PRIMARY)};
  }

  
`;

export const TransparentButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ color, disabled }) => (disabled ? TEXT_PLACEHOLDER  : color ? color : PRIMARY)};
  &:hover {
    background-color: ${({ color }) => (color ? color : PRIMARY)};
    color: white;
  }
`;

export const AltTransparentButton = styled(TransparentButton)`
  border: none;
  &:hover {
    background-color: ${({ backgroundColor, disabled }) => (disabled ? 'inherit' : backgroundColor ? backgroundColor : SECONDARY_DARK)};
    color: ${({ color, disabled }) => (disabled ? TEXT_PLACEHOLDER  : color ? color : PRIMARY)};
  }
  &:focus {
    outline: none;
  }
`;

export const TransparentDarkButton = styled(TransparentButton)`
  color: white;
`;

export const AcceptButton = ({ text, onClick, disabled, type = 'button', style }) => (
  <Card style={style}>
    <ColorButton type={type} color={disabled ? TEXT_PLACEHOLDER  : PRIMARY} onClick={disabled ? () => {} : onClick} style={{ disabled: disabled }}>
      {!!text ? text : 'Accept'}
    </ColorButton>
  </Card>
);

export const CancelButton = ({ text, dark = false, onClick, type = 'button' }) => {
  const Button = dark ? TransparentDarkButton : TransparentButton;

  return (
    <Card>
      <Button type={type} color={WRONG } onClick={onClick}>
        {!!text ? text : 'Cancel'}
      </Button>
    </Card>
  );
};

export const Link = styled.a`
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
`;

export const AcceptLink = styled(Link)`
  color: ${PRIMARY};
  font-size: 14px;

  @media( min-width: 768px ){
    font-size: 15px;
  }
`;

export const CancelLink = styled(Link)`
  color: ${WRONG};
`;