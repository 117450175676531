
const searchFilter = (search, list, translate) => {
    const searchTrim = search.toLowerCase().trim();
    
    const filtered = list.filter((reservation) => {
      return (
        reservation.guest?.firstName?.toLowerCase().includes(searchTrim) ||
          reservation.guest?.lastName?.toLowerCase().includes(searchTrim) ||
          reservation.referrer?.company?.toLowerCase().includes(searchTrim) ||
          reservation.referrer?.first_name?.toLowerCase().includes(searchTrim) ||
          reservation.referrer?.last_name?.toLowerCase().includes(searchTrim) ||
          reservation.link?.phone?.toLowerCase().includes(searchTrim) ||
          reservation.link?.code?.toLowerCase().includes(searchTrim) ||
          translate(reservation.localStatusCode)?.toLowerCase().includes(searchTrim)
      );
    });

    return filtered;
}

export default searchFilter;