import { takeLatest, takeEvery, put, all, call, select } from 'redux-saga/effects';
import { pendingChargesAPI } from 'resources/api';
import Types from './types';
import selector from './selector';
import { processError } from 'state/utils';

function* fetchStartAsync() {
  try {
    const data = yield select(selector);
    const res = yield pendingChargesAPI.list(data.pastDate, data.futureDate);
    yield put({ type: Types.FETCH_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeEvery(Types.FETCH_START, fetchStartAsync);
}

function* dateChangeAsync({ payload }) {
  yield put({ type: Types.UPDATE_DATE, payload });
  yield put({ type: Types.FETCH_START });
}

function* dateChange() {
  yield takeLatest(Types.CHANGE_DATE, dateChangeAsync);
}

export default function* sagas() {
  yield all([
    call(fetchStart), 
    call(dateChange),
  ]);
}
