import DEFAULT_NOTIFICATION, { viewReservation, extractSubAction } from "./default";
import { formatMoney } from "utils/text";

const process = (notification, translate, showReservationDialog) => {
    const type = extractSubAction(notification.type);

    const code = notification?.payload?.metadata?.code;
    const text = translate("VIEW_RESERVATION");

    switch (type) {
        case "SUCCEEDED": return { ...succeed(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        case "REQUIRES_ACTION": return { ...requiresAction(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        case "PROCESSING": return { ...processing(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        case "PAYMENT_FAILED": return { ...paymentFailed(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        case "CANCELLED": return { ...cancelled(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        case "CREATE": return { ...created(notification, translate), action: viewReservation(code, text, showReservationDialog) };
        default: return DEFAULT_NOTIFICATION(notification)
    }
}

function succeed(notification, translate) {
    return {
        title: translate("CHARGE_SUCCEEDED_TITLE"),
        message: translate("CHARGE_SUCCEEDED_MSG", {
            "amount": formatMoney(notification.payload.metadata.amount / 100)
        }),
        isRead: notification.isRead,
    }
}

function requiresAction(notification, translate) {

    return {
        title: translate("CHARGE_REQUERIES_ACTION_TITLE"),
        message: translate("CHARGE_REQUERIES_ACTION_MSG"),
        isRead: notification.isRead,
    }
}

function processing(notification, translate) {

    return {
        title: translate("CHARGE_PROCESSING_TITLE"),
        message: translate("CHARGE_PROCESSING_MSG"),
        isRead: notification.isRead,
    }
}

function paymentFailed(notification, translate, showReservationDialog) {
    const errorMsg = notification.payload.last_payment_error.message;
    return {
        title: translate("CHARGE_FAILED_TITLE"),
        message: translate('CHARGE_FAILED_MSG', {
            errorMsg,
        }),
        isRead: notification.isRead,
    }
}

function cancelled(notification, translate) {
    return {
        title: translate("CHARGE_CANCELLED_TITLE"),
        message: translate("CHARGE_CANCELLED_MSG"),
        isRead: notification.isRead,
    }
}

function created(notification, translate) {
    return {
        title: translate("CHARGE_CREATE_TITLE"),
        message: translate("CHARGE_CREATE_MSG"),
        isRead: notification.isRead,
    }
}

export default process;