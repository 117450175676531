import Axios from "axios";
import Authorization from './auth';
import { executeCall } from './utils';

const client = (domain, resource, withAuth = true) => {
    const url = `${domain}/${resource}`;

    const headers = withAuth ? Authorization : {};

    return {
        cancel: (uid) => {
            const customUrl = `${url}/cancel/${uid}`;

            return executeCall(() => Axios.get(customUrl, { headers }));
        }

    };
};

export default client;