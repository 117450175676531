import React, { useEffect, useState } from 'react';
import Header from 'components/header';
import MainContainer from 'containers/MainContainer';
import useReservations from 'state/reservations';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Reservations from './pages/Reservations';
import PendingCharges from './pages/PendingCharges';
import Disputes from './pages/Disputes';
import { useAlert } from 'alert';
import hookAlertTypes from 'alert/types';
import PendingReservations from './alert/PendingReservations';
import { getToday } from 'utils/date';
import { LOCAL_STATUS_CODE, OPENTABLE_STATUS_CODE } from 'resources/constants/status';

const routes = [
  { path: `/reservations`, title: 'Reservations', icon: 'calendar' },
  { path: `/pending`, title: 'Pending Charges', icon: 'price' },
  { path: `/disputes`, title: 'Disputes', icon: 'card' },
];

const HomePage = ({ location }) => {
  const [reservations] = useReservations();
  const { path } = useRouteMatch();
  const alert = useAlert();
  const [counter, setCounter] = useState(0);

  const showCancelledOpenTableActions = () => {
    const list = Object.keys(reservations.dates)
      .filter((d) => {
        const date = d.split('-').join('');
        const today = getToday().split('-').join('');
        return date >= today;
      })
      .map((key) => reservations.dates[key])
      .reduce((acc, curr) => acc.concat(curr), [])
      .filter(({ localStatusCode }) => localStatusCode === LOCAL_STATUS_CODE.CANCELLED)
      .filter(({ state }) => state !== OPENTABLE_STATUS_CODE.CANCELLED);

    if (list.length > 0) {
      alert({
        type: hookAlertTypes.CUSTOM,
        config: {
          body: PendingReservations,
        },
      }).catch((e) => {});
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < 9) {
        setCounter(counter + 1);
        return;
      }
      showCancelledOpenTableActions();
      setCounter(0);
    }, 60000);

    return () => clearInterval(interval);
  }, [reservations.dates, counter]);

  return (
    <MainContainer>
      <Header location={location} routes={routes} />
      <Switch>
        <Route exact path={path}>
          <Redirect to={`/reservations`} />
        </Route>
        <Route path="/reservations" component={Reservations} />
        <Route exact path="/pending" component={PendingCharges} />
        <Route exact path="/disputes" component={Disputes} />
      </Switch>
    </MainContainer>
  );
};

export default HomePage;
