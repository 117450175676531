import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getUsersOfGroup } from 'resources/api';
import { MANAGERS_DIVISIONS_AND_ROLES } from 'resources/constants/config';
import UserTypes from 'resources/constants/userTypes';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartAsync({payload}) {
  try {
    let list = [];

    switch(payload) {
      case UserTypes.MANAGERS: {
        for(const divRol of MANAGERS_DIVISIONS_AND_ROLES.split('|')){
          const [division, role] = divRol.split('-');
          const res = yield getUsersOfGroup({division, role});
          list = [...list, ...res];
        }
        break;
      }
      default: {
        const res = yield getUsersOfGroup({});
        list = res;
      }
    }
    
    yield put({ type: Types.FETCH_SUCCESS, payload: { key: payload, list} });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([
    call(fetchStart), 
  ]);
}
