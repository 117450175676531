import React from 'react';
import CardContainer from 'containers/CardContainer';
import CardLayout from 'containers/CardLayout';
import CardHeader from './components/Header';
import Table from 'components/Table';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import { useAlert } from 'alert';
import { useEffect } from 'react';
import styled from 'styled-components';
import useDisputes from 'state/disputes';
import { useSnacks } from 'snackbar';
import { TableContainer } from '@material-ui/core';

const Layout = styled.div`
  height: 95%;
  margin-top: 15px;
`;

const TableStyled = styled(Table)`
  padding: 0 10px;
  position: relative;
  margin-top: 15px;
`;

const PendingCharges = () => {
  const { translate, formatDate, translateSeconds } = useI18n();
  const alert = useAlert();
  const snacks = useSnacks();
  const [data, dispatcher] = useDisputes();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  return (
      <CardContainer>
        <CardLayout>
          <CardHeader />
          <TableContainer>
            <TableStyled
            intersectionId="disputes-table-container"
            structure={structure}
            config={{ translate, alert, snacks, formatDate, translateSeconds}}
            items={data.list}
          />
          </TableContainer>
          
        </CardLayout>
      </CardContainer>
  );
};

export default PendingCharges;
