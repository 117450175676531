import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import { nomenclatorAPI, reservationsAPI } from 'resources/api';
import Types from './types';
import { NOMENCLATORS_FAMILIES } from './constants';
import nomenclatorsSelector from '../nomenclators/selector';
import ReservationTypes from '../reservations/types';
import { RESERVATION_HOTEL_NAME } from 'resources/constants/config';
import { processError } from 'state/utils';

function* fetchStartAsync() {
    try {
        const nomenclators = Object.values(NOMENCLATORS_FAMILIES);
        for(const value of nomenclators){
            const res = yield nomenclatorAPI.getAll(value);
            yield put({ type: Types.FETCH_SUCCESS, payload: {family: value, data: res, complete: nomenclators.indexOf(value)===nomenclators.length-1}});
        }

    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
    yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* createStartAsync({payload}) {
    try {
        const res = yield nomenclatorAPI.create(payload);
        yield put({ type: Types.CREATE_SUCCESS, payload: {data: res, family: payload.family} });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.CREATE_ERROR, payload: message });
    }
}

function* createStart() {
    yield takeLatest(Types.CREATE_START, createStartAsync);
}

function* deleteStartAsync({payload}) {
    try {
        yield nomenclatorAPI.delete(payload.data);
        yield put({ type: Types.DELETE_SUCCESS });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.DELETE_ERROR, payload: message });
        yield put({ type: Types.FETCH_START });
    }
}

function* deleteStart() {
    yield takeLatest(Types.DELETE_START, deleteStartAsync);
}

function* submitStartAsync({payload}) {
    try {
        const nomenclatorsState = yield select(nomenclatorsSelector);
        const { family, reservationLink: { nomenclators, code } } = payload;
        
        let nomenclatorsIds = [];

        if(family === NOMENCLATORS_FAMILIES.RESERVATION_TYPE && nomenclatorsState[family].selected.label !== RESERVATION_HOTEL_NAME) {
            nomenclatorsIds = [+nomenclatorsState[family].selected.value];
        } else {
            nomenclatorsIds = Object.keys(nomenclators).filter((key)=> !!nomenclators[key] && key!==family).map((key)=>nomenclators[key].id);
            nomenclatorsIds = [...nomenclatorsIds, +nomenclatorsState[family].selected.value];
        }
        
        const req = { code, nomenclators: nomenclatorsIds };
        const res = yield reservationsAPI.nomenclators(req)
        yield put({ type: ReservationTypes.FETCH_START, payload: {start: res.date, end: res.date} });
        yield put({ type: Types.SUBMIT_SUCCESS, payload: {data: res, family } });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.SUBMIT_ERROR, payload: message });
    }
}

function* submitStart() {
    yield takeLatest(Types.SUBMIT_START, submitStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(createStart),
        call(deleteStart),
        call(submitStart),
    ]);
}
