import React from 'react';
import { Link } from 'react-router-dom';
import useI18n from 'i18n/useI18n';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import ChevronIcon from 'resources/img/chevron-icon-down.svg';
import TitleComponent from './TitleComponent';

import { 
  ChevronContainer, 
  Chevron, 
  Label, 
  ValueContainer, 
  SelectionsContainer, 
  SelectorContainer 
} from 'pages/home/pages/Reservations/styles';


const TextContainer = styled.div`
  display: flex;

  a {
    width: 100%;
    color: black;
    text-decoration: none;
    padding: 5px 5px 8px 5px;
    
    &:hover {
      text-decoration: none;
    }
  }
`;

const TabsSelector = ({ routes = [], current }) => {
  const { translate } = useI18n();
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    setVisible(false);
  }, [current]);

  useEffect(() => {
    const itemFiltered = routes.filter(({path}) => path === current);
    if( itemFiltered.length !== 0 ){
      setCurrentItem( itemFiltered[0] );
    } else { setCurrentItem( routes[0] ) }

  }, [current, routes]);

  return (
    <SelectorContainer style={{ width: '350px', margin: 'auto', }}>
      <ValueContainer onClick={() => setVisible(!visible)}>
        <Label>{translate('Status')}</Label>
        <TextContainer>
          <TitleComponent 
            hasAlert={false} 
            positive={currentItem.positive}
            text={`${translate(currentItem.title)}`} 
            count={currentItem.count} 
          />
        </TextContainer>
        <ChevronContainer>
          <Chevron src={ChevronIcon} alt={'select-status'} />
        </ChevronContainer>
      </ValueContainer>
      {visible && (
        <SelectionsContainer>
          {routes.map(({ path, title, count, hasAlert, positive }, i) => (
              <TextContainer  key={i}>
                <Link to={path}>
                  <TitleComponent positive={positive} hasAlert={hasAlert} text={`${translate(title)}`} count={count} />
                </Link>
              </TextContainer>
            ))}
        </SelectionsContainer>
      )}
    </SelectorContainer>
  );
};

export default TabsSelector;
