import React, { useState, useEffect } from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import UserTypes from 'resources/constants/userTypes';
import useUsers from 'state/users';

const LastUpdatedBy = ({ item, field = 'updatedBy' }) => {
  const [users, dispatcher] = useUsers();
  const [name, setName] = useState('');

  useEffect(() => {
    dispatcher.fetchStart(UserTypes.ALL);
  }, []);

  useEffect(() => {
    const user = users[UserTypes.ALL].find(({ id }) => id === (item.link ? item.link[field] : null));
    if (!!user) {
      setName(user.name);
    }
  }, [users]);

  return <Text text={name} />;
};

export default LastUpdatedBy;
