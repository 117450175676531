import React, { useState, useEffect } from 'react';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import useReservations from 'state/reservations';
import { getToday } from 'utils/date';
import { LOCAL_STATUS_CODE, OPENTABLE_STATUS_CODE } from 'resources/constants/status';
import Table from 'components/Table';
import styled from 'styled-components';
import Banner from './Banner';

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 45px minmax(0, 1fr);
`;

const Container = styled.div`
  width: 80vw;
  height: 60vh;
  min-width: 0;
  max-height: 100%;
  padding: 0px;
  flex: 1;
  overflow: scroll;
  position: relative;

  @media( min-width: 768px ){
    padding: 0 36px;
  }
`;

const Cancelled = () => {
  const { translate, formatDate, translateSeconds } = useI18n();
  const [reservations] = useReservations();
  const [data, setData] = useState([]);

  useEffect(() => {
    const list = Object.keys(reservations.dates)
      .filter((d) => {
        const date = d.split('-').join('');
        const today = getToday().split('-').join('');
        return date >= today;
      })
      .sort((a, b) => {
        a = a.split('-').join('');
        b = b.split('-').join('');
        return a > b ? 1 : a < b ? -1 : 0;
      })
      .map((key) => reservations.dates[key])
      .reduce((acc, curr) => acc.concat(curr), [])
      .filter(({ localStatusCode, link }) => localStatusCode === LOCAL_STATUS_CODE.CANCELLED && !!link)
      .filter(({ state }) => state !== OPENTABLE_STATUS_CODE.CANCELLED);

    setData(list);
  }, [reservations.dates]);

  return (
    <Grid>
      <Banner>{translate('Please cancel all these reservations in Opentable.')}</Banner>
      <Container>
        {Object.keys(reservations.dates).length > 0 && (
          <Table structure={structure} config={{ data, translate, formatDate, translateSeconds }} isLoading={reservations.isFetching} items={data} />
        )}
      </Container>
    </Grid>
  );
};

export default Cancelled;
