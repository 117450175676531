import { Text } from 'components/Text';
import React from 'react';
import { WRONG, DISABLED_BUTTON } from '@etiquette-ui/colors';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 0px 0px 24px 24px;
  background-color: ${DISABLED_BUTTON};
  padding: 12px 36px;
  display: flex;
  align-items: center;

  @media( max-width: 768px ){
    border-radius: 0px 0px 12px 12px;
    padding: 10px;
  }
`;

const Title = styled(Text)`
  font-style: italic;
  font-weight: 700;
  color: ${WRONG};

  @media( max-width: 768px ){
    font-size: 12px;
  }
`;

const Banner = ({ children }) => {
  return (
    <Container>
      <Title>{children}</Title>
    </Container>
  );
};

export default Banner;
