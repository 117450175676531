import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PRIMARY, TEXT } from '@etiquette-ui/colors';
import MenuHeader from './MenuHeader';
import Logout from './Logout';
const Background = styled.div`
  position: fixed;
  background-color: ${TEXT};
  opacity: 0.0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.2s;
  &.open {
    opacity: 0.5;
    pointer-events: all;
  }
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Menu = styled.div`
  position: fixed;
  background-color: ${PRIMARY};
  right: -75vw;
  top: 0;
  width: 75vw;
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  z-index: 6;
  pointer-events: none;
  transition-property: right;
  transition-duration: 0.3s;
  &.open {
    right: 0;
    pointer-events: all;
  }
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const LateralMenu = ({ toggle, open }) => {

  const [animate, setAnimate] = useState(false);

  useEffect(()=>{
    setAnimate(true);
  }, []);

  const close =() => {
    setAnimate(false);
    setTimeout(()=>{
      toggle();
    }, 300);
  }

  return (
    <>
      <Background onClick={close} className={open ? 'open' : ''} />
      <Menu className={animate ? 'open' : ''}>
        <MenuHeader toggle={close} />
        <Logout />
      </Menu>
    </>
  );
};

export default LateralMenu;
