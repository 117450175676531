import useI18n from 'i18n/useI18n';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Nav as DefaultNav,
  ItemContainer as DefaultItemContainer,
  Item as DefaultItem,
  IndicatorContainer,
  Indicator,
} from 'components/Tabs/styles';
import isCurrent from 'components/Tabs/isCurrent';
import TitleComponent from './TitleComponent';
import styled from 'styled-components';
import { SECONDARY_DARK, TEXT } from '@etiquette-ui/colors';

const Nav = styled(DefaultNav)`
  background-color: ${SECONDARY_DARK};
  padding: 12px 15px 10px 15px;
  align-self: center;
`;

const ItemContainer = styled(DefaultItemContainer)`
  position: relative;
  flex: ${({ flex }) => flex};
  min-width: unset;
  padding-right: 10px;
  margin-right: 5px;
  display: flex;
  align-items: start;
`;

const Item = styled(DefaultItem)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  & > a {
    color: ${TEXT};
  }
`;


const Tabs = ({ routes = [], pathIndex, current, className }) => {
  const { translate } = useI18n();

  return (
    <Nav className={className}>
      {routes.map(({ path, title, count, positive, flex, hasAlert }, i) => (
        <ItemContainer key={i} flex={flex}>
          <Item positive={positive}>
            <Link to={path} className={isCurrent(path, current, pathIndex) ? 'current' : ''}>
              <TitleComponent positive={positive} hasAlert={hasAlert} text={`${translate(title)}`} count={count} />
            </Link>
            <IndicatorContainer>{isCurrent(path, current, pathIndex) && <Indicator />}</IndicatorContainer>
          </Item>
        </ItemContainer>
      ))}
    </Nav>
  );
};

export default Tabs;
