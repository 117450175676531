import { useEffect } from "react";
import { API } from "resources/constants/urls";
import openSocket from "socket.io-client";
import useReservations from "state/reservations";
import useNotifications from "state/notifications";
import useDate from "state/date";

const socket = openSocket(`${API}/`, {
  path: "/reservations.socket",
  secure: true,
  jsonp: false,
  transports: ['websocket']
});

export const Socket = () => {

  const [, dispatcher] = useReservations();
  const [, notificationDispatcher] = useNotifications();

  useEffect(() => {

    socket.on('notification', (payload) => {
      if (
        payload.entity &&
        payload.entity === "RESERVATION") {
        dispatcher.fetchPast();
        dispatcher.fetchFuture();
      }
      notificationDispatcher.setReaded(false)
    });

    return () => {
      socket.off('charge');
      socket.off('notification');
    }
  }, []);

  return (<></>);
};



export default Socket;