export const SECONDS_IN_A_MINUTE = 60;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MINUTES_IN_AN_HOUR = 60;
export const SAFETY_PERIOD = 10;
export const ALERT_THRESHOLD = 5 * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND; 

export const getTimeUntilExpiration = (reservation) => {
    const createdAt = reservation.link.createdAt;
    const expirationSeconds = reservation.link.expirationSeconds;
    
    const expirationMilliseconds = (expirationSeconds - SAFETY_PERIOD) * MILLISECONDS_IN_A_SECOND;
    const expirationTime = new Date(createdAt).getTime() + expirationMilliseconds;

    const currentTime = new Date().getTime();
    const newCountDown = expirationTime - currentTime;

    return newCountDown < 0 ? 0 : newCountDown;
}

export const getTimeFromExpiration = (reservation) => {
    const createdAt = reservation.link.createdAt;
    const expirationSeconds = reservation.link.expirationSeconds;
    
    const expirationMilliseconds = (expirationSeconds - SAFETY_PERIOD) * MILLISECONDS_IN_A_SECOND;
    const expirationTime = new Date(createdAt).getTime() + expirationMilliseconds;

    const currentTime = new Date().getTime();
    const newCountDown = expirationTime - currentTime;

    return newCountDown < 0 ? newCountDown * -1 : newCountDown;
}

export function formatTime(secondsInteger) {
    if (secondsInteger === false || secondsInteger < 0) return "-- : --";

    const counter = secondsFromMiliseconds(secondsInteger);

    const hours = hoursFromInteger(counter)
    const secondsInHours = hoursToSeconds(hours)

    const minutes = minutesFromInteger(counter - secondsInHours);
    const secondsInMinutes = minutesToSecods(minutes)

    const seconds = (counter - ( secondsInHours + secondsInMinutes )).toFixed(0);

    return `${hours ? formatNumber(hours, false)+":" : ""}${formatNumber(minutes, !!hours)}:${formatNumber(seconds)}`;
}

export function secondsFromMiliseconds(seconds){
    return seconds / MILLISECONDS_IN_A_SECOND;
}

export function hoursFromInteger(secondsInteger) {
    const minutes = minutesFromInteger(secondsInteger);
    return Math.floor(minutes / MINUTES_IN_AN_HOUR)
}

export const hoursToSeconds = (hours) => {
    return hours * MINUTES_IN_AN_HOUR * SECONDS_IN_A_MINUTE;
}

export function minutesToSecods(minutes){
    return minutes * SECONDS_IN_A_MINUTE
}

export function minutesFromInteger(secondsInteger) {
    return Math.floor(secondsInteger / SECONDS_IN_A_MINUTE);
}


export function formatNumber(value, leadingZero = true) {
    if (isNaN(value)) return "--";

    return `${(value < 10 && leadingZero) ? '0' : ''}${value}`;
}

export const millisecondsFromDate = (date) => {
    const current = new Date().getTime();
    const dateInMilliseconds = new Date(date).getTime();

    return current-dateInMilliseconds;
}