import React from 'react';
import useStep from 'hooks/useStep';
import ButtonsStep from './ButtonsStep';
import TimeUpdateStep from '../../../../cells/TimeUpdateStep';
import useReservations from 'state/reservations';
import { millisecondsFromDate } from 'utils/time';

const ResetTime = ({ item }) => {
  const [step, previous, next] = useStep();
  const [, dispatcher] = useReservations();

  const updateLink = (time, multiplier) => {
    const secondsFromSelector = time*multiplier;
    const secondsToAdd = Math.floor(millisecondsFromDate(item.link.createdAt)/1000) + secondsFromSelector;

    previous();
    dispatcher.updateLink({code: item.link.code, expirationSeconds: secondsToAdd});
  };

  const cancel = () => {
    dispatcher.cancel(item.link.code);
  };

  return (
    <>
    <ButtonsStep item={item} action={next} cancel={cancel} currentStep={step}/>
    <TimeUpdateStep item={item} previous={previous} currentStep={step} updateLink={updateLink}/>
    </>
  );
};

export default ResetTime;
