import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Select = styled.select`
    height: auto;
    padding: 4px;
    border-radius: 3px;
    background-color: transparent;
    border: none;
    width: ${({ width }) => (width ? `${width}` : '100%')};
    &:focus {
        border: none;
        outline: none;
    }
`;

const Selector = ({
  options,
  handleOptions,
  selected,
  name,
  disabled,
  width,
  className,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    const newValue = !!selected ? selected : options[0].id + ""
    setValue(newValue)
  }, [selected]);

  return (
    <Select
      onChange={handleOptions}
      value={value}
      name={name}
      disabled={disabled === true}
      width={width}
      className={className}
    >
      {!!options &&
        options.map((option, i) => (
          <option key={name + `${i}`} value={option.id + ""}>{option.label}</option>
        ))}
    </Select>
  );
};

export default Selector;