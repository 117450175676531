const TYPE = `[RESERVATIONS]`;

const TYPES = {
    FETCH_PAST: `${TYPE} FETCH PAST`,
    FETCH_FUTURE: `${TYPE} FETCH FUTURE`,
    FETCH_ALL: `${TYPE} FETCH ALL`,

    IS_FETCHING:  `${TYPE} IS FETCHING`,
    STOP_FETCHING:  `${TYPE} STOP FETCHING`,

    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_LOADONE_START: `${TYPE} FETCH LOAD ONE START`,
    FETCH_LOADONE_SUCCESS: `${TYPE} FETCH LOAD ONE SUCCESS`,
    FETCH_LOADONE_ERROR: `${TYPE} FETCH LOAD ONE ERROR`,

    UPDATE_ALL: `${TYPE} UPDATE ALL`,

    CREATE_LINK_START: `${TYPE} CREATE LINK START`,
    CREATE_LINK_SUCCESS: `${TYPE} CREATE LINK SUCCESS`,
    CREATE_LINK_ERROR: `${TYPE} CREATE LINK ERROR`,

    UPDATE_LINK_START: `${TYPE} UPDATE LINK START`,
    UPDATE_LINK_SUCCESS: `${TYPE} UPDATE LINK SUCCESS`,
    UPDATE_LINK_ERROR: `${TYPE} UPDATE LINK ERROR`,

    UPDATE_CHARGE_START: `${TYPE} UPDATE CHARGE START`,

    CHARGE_START: `${TYPE} CHARGE START`,
    CHARGE_SUCCESS: `${TYPE} CHARGE SUCCESS`,
    CHARGE_ERROR: `${TYPE} CHARGE ERROR`,

    CANCEL_START: `${TYPE} CANCEL START`,
    CANCEL_SUCCESS: `${TYPE} CANCEL SUCCESS`,
    CANCEL_ERROR: `${TYPE} CANCEL ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;