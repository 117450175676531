import React from 'react';
import useStep from 'hooks/useStep';
import TimerStep from './TimerStep';
import TimeUpdateStep from '../../../../cells/TimeUpdateStep';
import useReservations from 'state/reservations';

const ExtendTime = ({ item }) => {
  const [step, previous, next] = useStep();
  const [, dispatcher] = useReservations();

  const updateLink = (time, multiplier) => {
    const secondsFromSelector = time*multiplier;
    const secondsToAdd = item.link.expirationSeconds + secondsFromSelector;

    previous();
    dispatcher.updateLink({code: item.link.code, expirationSeconds: secondsToAdd});
  };

  return (
    <>
    <TimerStep item={item} action={next} currentStep={step}/>
    <TimeUpdateStep item={item} previous={previous} currentStep={step} updateLink={updateLink}/>
    </>
  );
};

export default ExtendTime;
