import { getToday } from 'utils/date';
import Types from './types';
import { DATE_RANGE_TYPES } from 'resources/constants/selectors';
import { DEFAULT_NO_ERROR } from 'state/constants';


const INITIAL_STATE = {
    list: [],
    pastDate: getToday(),
    futureDate: getToday(),
    dateRangeType: DATE_RANGE_TYPES.MONTH,
    isFetching: false,
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, isFetching: true }        
        case Types.FETCH_SUCCESS: return { ...state, list: action.payload, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, isFetching: false, error: {timestamp: Date.now(), message: action.payload} }

        case Types.UPDATE_DATE: return { ...state, pastDate: action.payload.start, futureDate: action.payload.end }

        case Types.CHANGE_DATE_TYPE: return { ...state, dateRangeType: action.payload}

        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
}

export default reducer