import Types from "./types";

export default class Dispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = (payload) => this.dispatch({ type: Types.FETCH_START, payload });

    setReaded = (readed) => {
        if (readed) this.dispatch({ type: Types.READED_START });

        this.dispatch({ type: Types.SET_READED, payload: readed });
    }

    setAllNotificationsReaded = () => this.dispatch({ type: Types.SET_ALL_NOTIFICATIONS_READED })

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}