import React from 'react';
import { Text } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedDate } from 'utils/date';
import { formatMoney } from 'utils/text';
import styled from 'styled-components';
import { RIGHT_V_LIGHT, WRONG_V_LIGHT   } from '@etiquette-ui/colors';

const Row = styled(RowContainer)`
  margin: 5px 0;
  background-color: ${({item})=> item.status_code === 'succeeded' ? `${RIGHT_V_LIGHT}` : `${WRONG_V_LIGHT}`};
`;

const structure = {
  row: Row,
  columns: [
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Date and Time')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.formatDate(`${item.date}T${item.time}`)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={item.client} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text style={{justifyContent: 'center'}} text={globalState.config.translate('#ppl')} />,
      body: ({ item }) => <Text style={{justifyContent: 'center'}} text={item.ppl} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text text={globalState.config.translate('Total')} />,
      body: ({ item }) => <Text text={formatMoney(item.total/100)} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text text={globalState.config.translate('Rejected')} />,
      body: ({ item }) => <Text text={item.status_code !== 'succeeded' ? formatMoney(item.total/100) : ''} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text text={globalState.config.translate('Collected')} />,
      body: ({ item }) => <Text text={item.status_code === 'succeeded' ? formatMoney(item.total/100) : ''} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <Text text={globalState.config.translate('Status')} />,
      body: ({ item, globalState }) => <Text text={globalState.config.translate(item.status_code)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <Text text={globalState.config.translate('Last Attempt')} />,
      body: ({ item }) => <Text text={getFormattedDate(item.lastAttempt)} />,
    },
  ],
};

export default structure;
