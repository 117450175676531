import React, { useState, useEffect } from 'react';
import { INPUTS } from '@etiquette-ui/colors';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { Container, Icon, Action, Info, InfoAlt } from './styles';
import { AltTransparentButton } from 'components/Buttons';
import useI8n from 'i18n/useI18n';
import useNomenclators from 'state/nomenclators';

const CreatableSelector = styled(CreatableSelect)`
    .react-select__control {
        background-color: ${INPUTS};
        outline: none !important;
        border: none !important;
        &:focus {
            outline: none !important;
            border: none !important;
        }
        &:hover {
            outline: none !important;
            border: none !important;
        }
    }
    .react-select__indicator-separator {
        display: none;
    }

    .react-select__menu {
        position: absolute;
    }
`;

const Selector = styled(Select)`
    .react-select__control {
        background-color: ${INPUTS};
        outline: none !important;
        border: none !important;
        &:focus {
            outline: none !important;
            border: none !important;
        }
        &:hover {
            outline: none !important;
            border: none !important;
        }
    }
    .react-select__indicator-separator {
        display: none;
    }

    .react-select__menu {
        position: absolute;
    }
`;

const DeleteButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    width: 40px;
`;

const Option =
    (onDelete) =>
    ({ data, value, options, ...props }) => {
        return (
            <div style={{ display: 'flex' }}>
                <components.Option data={data} value={value} options={options} {...props} />
                {!options.find((opt) => opt.value == value)?.__isNew__ && (
                    <DeleteButton type="button" onClick={() => onDelete(data)}>
                        X
                    </DeleteButton>
                )}
            </div>
        );
    };

const Selectable = ({ creatable, isFetching, placeholder, data, family, onChange }) => {
    const [options, setOptions] = useState([]);
    const [, dispatcher] = useNomenclators();

    useEffect(() => {
        setOptions(data.list.map((opt) => ({ value: `${opt.id}`, label: opt.name })));
    }, [data.list]);

    const handleCreate = (inputValue) => {
        dispatcher.createStart({ name: inputValue, family: family });
    };

    const handleDelete = (selected) => {
        const tmpOptions = [...options];
        const index = options.indexOf(selected);
        if (index > -1) {
            tmpOptions.splice(index, 1);
            setOptions(tmpOptions);
            dispatcher.deleteStart({ data: { id: selected.value }, family: family });
        }
    };

    return creatable ? (
        <CreatableSelector
            classNamePrefix="react-select"
            isLoading={isFetching}
            onCreateOption={handleCreate}
            placeholder={placeholder}
            isSearchable={true}
            value={data.selected}
            defaultMenuIsOpen={false}
            onChange={onChange}
            options={options}
            maxMenuHeight={100}
            components={{ Option: Option(handleDelete) }}
        />
    ) : (
        <Selector
            classNamePrefix="react-select"
            isLoading={isFetching}
            onCreateOption={handleCreate}
            placeholder={placeholder}
            isSearchable={true}
            value={data.selected}
            defaultMenuIsOpen={false}
            onChange={onChange}
            options={options}
            maxMenuHeight={100}
        />
    );
};

const NomenclatorSelector = ({ family, title, icon, creatable = true }) => {
    const { translate } = useI8n();
    const [isEditing, setEditing] = useState(false);
    const [nomenclators, dispatcher] = useNomenclators();
    const data = nomenclators[family];
    const isFetching = nomenclators.isFetching;

    return (
        <Container withAction={true}>
            <Icon src={icon} />
            <InfoAlt>
                {isEditing ? (
                    <Selectable
                        creatable={creatable}
                        isFetching={isFetching}
                        placeholder={translate('Select')}
                        data={data}
                        family={family}
                        onChange={(opt) => {
                            dispatcher.select({ family, data: opt });
                            dispatcher.confirm({ family, data: true });
                            setEditing(false);
                        }}
                    />
                ) : data.confirmed ? (
                    <Info>{data.selected.label}</Info>
                ) : (
                    <Action onClick={() => setEditing(true)}>{title}</Action>
                )}
            </InfoAlt>
            {isEditing ? (
                <AltTransparentButton
                    onClick={() => {
                        dispatcher.confirm({ family, data: true });
                        setEditing(false);
                    }}
                    style={{ maxWidth: '100%', padding: '10px 0' }}
                >
                    {translate('Add')}
                </AltTransparentButton>
            ) : data.confirmed ? (
                <AltTransparentButton onClick={() => setEditing(true)} style={{ maxWidth: '100%', padding: '10px 0' }}>
                    {translate('Edit')}
                </AltTransparentButton>
            ) : null}
        </Container>
    );
};

export const NomenclatorSelectorEdit = ({ reservation, family, title, icon, creatable = true }) => {
    const { translate } = useI8n();
    const [isEditing, setEditing] = useState(false);
    const [nomenclators, dispatcher] = useNomenclators();
    const data = nomenclators[family];
    const isFetching = nomenclators.isFetching;
    const [initialValue, setInitialValue] = useState(null);

    useEffect(() => {
        if (!reservation?.link.nomenclators) return;

        const element = reservation?.link.nomenclators[family];
        if (!element) return;
        setInitialValue({ value: element.id + '', label: element.name });
    }, [reservation]);

    const handleSubmit = () => {
        if (!!data.selected) dispatcher.submitStart({ family, reservationLink: reservation.link });
        setEditing(false);
    };

    const startEdit = () => {
        setEditing(true);
        dispatcher.select({ family, data: initialValue });
    }

    return (
        <Container withAction={true}>
            <Icon src={icon} />
            <InfoAlt>
                {isEditing ? (
                    <Selectable
                        creatable={creatable}
                        isFetching={isFetching}
                        placeholder={translate('Select')}
                        data={data}
                        family={family}
                        onChange={(opt) => {
                            dispatcher.select({ family, data: opt });
                        }}
                    />
                ) : data.confirmed || !!initialValue ? (
                    <Info>{data.confirmed ? data.selected.label : initialValue.label}</Info>
                ) : (
                    <Action onClick={startEdit}>{title}</Action>
                )}
            </InfoAlt>
            {isEditing ? (
                <AltTransparentButton disabled={initialValue?.value===data.selected?.value} onClick={initialValue?.value===data.selected?.value || isFetching ? ()=>{} : handleSubmit} style={{ maxWidth: '100%', padding: '10px 0' }}>
                    {isFetching ? '...' : translate('Save')}
                </AltTransparentButton>
            ) : !!initialValue ? (
                <AltTransparentButton onClick={isFetching ? () => {} : startEdit} style={{ maxWidth: '100%', padding: '10px 0' }}>
                     {isFetching ? '...' : translate('Edit')}
                </AltTransparentButton>
            ) : null}
        </Container>
    );
};

export default NomenclatorSelector;
