import styled from "styled-components";
import { TEXT, WRONG } from "@etiquette-ui/colors";

export const BoldText = styled.p`
  font-weight: 600;
  margin: 0;
  padding: 5px 0px;
  font-size: 14px;
  color : ${({ color }) => (color ? color : TEXT)};

  @media (min-width: 768px){
    font-size: 15px;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 600;
  color : ${({ color }) => (color ? color : TEXT)};
  margin: ${({ margin }) => (margin ? margin : '0')};

  @media( min-width: 768px ){
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const InvalidText = styled.p`
  margin: 0;
  font-size: 0.75rem;
  color: ${WRONG};
`;