import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronIcon from 'resources/img/chevron-icon-down.svg';
import CalendarSVG from 'resources/img/calendar-icon.svg';
import { Text } from 'components/Text';
import CalendarView from './CalendarView';
import useI18n from 'i18n/useI18n';
import { useEffect } from 'react';
import { DATE_RANGE_TYPES } from 'resources/constants/selectors';
import usePendingCharges from 'state/pendingCharges';
import { DISPLAY_DATE_FORMAT } from 'utils/date';
import { Card } from "@etiquette-ui/misc";

const Container = styled(Card)`
  width: 330px;
  max-width: 100%;
  justify-self: flex-start;
  margin: auto;
  position: relative;

  @media( min-width: 1001px ){
    margin-left: 2.5%;
  }
`;

const ValueContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const Chevron = styled.img`
  position: relative;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const Calendar = styled.img`
  position: relative;
  height: 35px;
  width: 35px;
`;

const Date = styled(Text)`
  flex: 1;
  margin: 0 10px;
`;

const DateRange = () => {
  const [visible, setVisible] = useState(false);
  const [pending] = usePendingCharges(); 
  const { formatDate } = useI18n();
  const [date, setDate] = useState('');
  
  useEffect(()=>{
    const type = pending.dateRangeType;
    if(type===DATE_RANGE_TYPES.DAY){ 
      setDate(formatDate(`${pending.pastDate}T00:00:00`, DISPLAY_DATE_FORMAT));
      return;
    }
    if(type===DATE_RANGE_TYPES.MONTH){ 
      setDate(formatDate(`${pending.pastDate}T00:00:00`, 'MMMM'));
      return;
    }
    setDate(`${pending.pastDate} - ${pending.futureDate}`);
  },[pending.dateRangeType, pending.pastDate, pending.futureDate, formatDate])

  return (
    <Container>
      <ValueContainer>
        <Calendar src={CalendarSVG} alt={'calendar'} />
        <Date>{date}</Date>
        <Chevron src={ChevronIcon} alt={'select date range'} onClick={() => setVisible(!visible)} />
      </ValueContainer>
      {visible && <CalendarView />}
    </Container>
  );
};

export default DateRange;
