import React, { useState, useEffect } from 'react';
import { DATE_RANGE_TYPES } from 'resources/constants/selectors';
import { Container } from './styles';
import CalendarPicker from 'components/CalendarPicker';
import usePendingCharges from 'state/pendingCharges';
import { getFormattedDate } from 'utils/date';

const RangeView = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [charges, dispatcher] = usePendingCharges();

  useEffect(()=>{
    setStartDate(new Date(charges.pastDate + 'T00:00:00'));
    setEndDate(new Date(charges.futureDate + 'T00:00:00'));
  }, []);

  useEffect(()=>{
    if (!startDate || !endDate) return;

    dispatcher.changeDate({start: getFormattedDate(startDate.toString()), end: getFormattedDate(endDate.toString())});
  },[startDate, endDate]);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return charges.dateRangeType===DATE_RANGE_TYPES.RANGE ? (
    <Container>
      <CalendarPicker
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        selectsRange={true}
      />
    </Container>
  ) : null;
};

export default RangeView;
