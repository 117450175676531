import Axios from "axios";
import Authorization from './auth';
import { executeCall } from './utils';

const disputes = (domain, resource) => {
  const url = `${domain}/${resource}`;

  const headers = Authorization;

  return {
    list: () => executeCall(() => Axios.get(url + `/`, { headers })),
    view: (id) => executeCall(() => Axios.get(url + `/${id}`, { headers })),
    document: (id) => executeCall(() => Axios.get(url + `/document/${id}`, { headers })),
  };
}

export default disputes;