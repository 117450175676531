import React from 'react';
import styled from "styled-components";
import { useAlert } from 'alert';
import hookAlertTypes from 'alert/types';
import { getFormattedLastName } from 'utils/text';
import { shouldBeClickable, needsCharge, chargePending, chargeSuccess } from 'utils/status';
import useI8n from "i18n/useI18n";
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import TimerComponent from 'pages/home/pages/Reservations/components/TimerComponent';
import { getAlertBody } from 'pages/home/pages/Reservations/alert/utils';
import OkSVG from 'resources/img/ok-icon.svg';
import CloseSVG from 'resources/img/close-icon-red.svg';


export const getActionComponent = (reservation, translate) => {
  switch (reservation.localStatusCode) {
    case LOCAL_STATUS_CODE.CREATE:
      return <Action>{translate('Create Link')}</Action>;
    case LOCAL_STATUS_CODE.WAITING:
      return <TimerComponent reservation={reservation} withAlert={false} />;
    case LOCAL_STATUS_CODE.EXPIRED:
      return <Action>{translate('Call Customer')}</Action>;
    case LOCAL_STATUS_CODE.NO_SHOW:
      if (needsCharge(reservation)) return <Action>{translate('Add Charge')}</Action>;
      if (chargePending(reservation)) return <Action>{translate('Charge Pending')}</Action>;
      if (chargeSuccess(reservation)) return <Action>{translate('Charge Completed')}</Action>;
      if (!!reservation.link?.electedNoCharge) return <Action>{translate('Approved No Charge')}</Action>;
      return <Action>{translate('Charge Rejected')}</Action>;
    case LOCAL_STATUS_CODE.CANCELLED:
      return <Logo src={CloseSVG} />;
    case LOCAL_STATUS_CODE.ATTENDED:
      return <Logo src={OkSVG} />;
    case LOCAL_STATUS_CODE.CONFIRMED:
      return '';
    default:
      return null;
  }
};

const ScheduleElements = ({ element, color, backgroundColor, border, statusText }) => {
  const { translate } = useI8n();
  const alert = useAlert();
  const onAlert = (e, element) => {
    e.preventDefault();
    alert({
      type: hookAlertTypes.CUSTOM,
      config: {
        props: {
          id: element.id,
          statusText: statusText,
        },
        body: getAlertBody(element),
      },
    }).catch((e) => { });
  }
  const cursorEvent = shouldBeClickable(element) ? 'pointer' : 'default';
  const clickable = shouldBeClickable(element) ? 'auto' : 'none';
  return (
    <AppointmentContent color={color} backgroundColor={backgroundColor} border={border} text={statusText} onClick={(e) => onAlert(e, element)} cursor={cursorEvent} isClickable={clickable}>
      <TitleName>{getFormattedLastName(element.guest)}</TitleName>
      <StatuLogoContainer>
        <Status>{translate(element.localStatusCode)}</Status>
        {getActionComponent(element, translate)}
      </StatuLogoContainer>
    </AppointmentContent>
  )
}



const AppointmentContent = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: ${({ cursor }) => cursor};
  pointer-events: ${({ isClickable }) => isClickable};
  border-radius: 10px;
  width: 280px;
  height: 58px;
  margin-left: 15px;
  color: ${({ color }) => color};
  border: solid 1px ${({ border }) => border};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 8px;
`;

const TitleName = styled.p`
  margin: 0;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: 1;
`;

const StatuLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Status = styled.p`
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: 1;
`;

const Action = styled(Status)`
  font-weight: 700;
  font-size: 1rem;
`;

const Logo = styled.img`
  height: 20px;
`;

export default ScheduleElements;