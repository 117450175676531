import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Container from "./styles";
import Header from "./Header";
import useI18n from "i18n/useI18n";

import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

registerLocale("es", es);
registerLocale("en", en);

const CalendarPicker = ({ selected, highlighted = [], loading = false, ...rest }) => {
  const highlightedDates = !!highlighted
    ? highlighted.map((date) => new Date(date))
    : [];

  const { selectedLang } = useI18n();

  return (
    <Container>
      <DatePicker
        {...rest}
        selected={selected}
        calendarClassName={loading ? "loading" : ""}
        utcOffset={-1}
        inline
        disabledKeyboardNavigation
        highlightDates={highlightedDates}
        renderCustomHeader={Header}
        locale={selectedLang ? en : es}
      />
    </Container>
  );
};

export default CalendarPicker;
