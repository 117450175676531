import React from 'react';
import IconButton from 'components/Buttons/IconButton';
import styled from 'styled-components';

const LogOutButton = styled( IconButton )`
  display: none;
  order: 4;

  @media( min-width: 769px ){
    display: block;
  }
`;

const LogoutButton = () => {

  const logout = () => {
    localStorage.removeItem('auth');
    window.location.reload();
  };

  return <LogOutButton onClick={logout} icon={'logout'} isMenuItem={true} text={''} />;
};

export default LogoutButton;
