import React from 'react';
import styled from 'styled-components';
import { ADMIN_IMAGES } from 'resources/constants/urls';

const Container = styled.div`
  display: flex;
  order: 3;
  align-items: center;
  justify-content: space-between;
  color: white;
  @media (max-width: 768px) {
    display: none;
  }
  @media( min-width: 769px ) and ( max-width: 1000px){
    color: black;
  }
`;

const User = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  return (
    <Container>
      <Img style={{ maxHeight: '2.5rem' }} src={`${ADMIN_IMAGES}${auth.image}`} alt={'user'} />
      <span style={{ marginLeft: '10px' }}>{auth.name}</span>
    </Container>
  );
};

const Img = styled.img`
  max-height: 3rem;
  border-radius: 50%;
`;

export default User;
