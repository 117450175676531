import React from 'react';
import styled from 'styled-components';
import logoPNG from 'resources/img/reservations.svg';
import Icon from '@etiquette-ui/icons';
import { PSmall, Subtitle } from '@etiquette-ui/typography';
import { ADMIN_IMAGES } from 'resources/constants/urls';

const Container = styled.div`
  background-color: white;
  height: 200px;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px -4px 12px #03326914;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 600;
  }
`;

const Logo = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 10px;
`;

const Close = styled.div`
  width: 20px;
  height: 20px;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfilePicture = styled.img`
  max-height: 3rem;
  border-radius: 50%;
`;

const MenuHeader = ({toggle}) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  return (
    <Container>
      <TitleContainer>
        <LogoContainer>
          <Logo src={logoPNG} alt={'managersite-logo'} />
          <PSmall>Reservations</PSmall>
        </LogoContainer>
        <Close onClick={toggle} >
          <Icon icon={'wrong'} styles={{ line: { fill: 'none', strokeWidth:'1.5px', stroke:'black' } }} />
        </Close>
      </TitleContainer>
      <ProfileContainer>
        <Profile>
          <ProfilePicture style={{ maxHeight: '3rem' }} src={`${ADMIN_IMAGES}${auth.image}`} alt={'user'} />
          <div style={{ marginLeft: '10px' }}>
            <Subtitle>{auth.name}</Subtitle>
            <PSmall>{auth.email}</PSmall>
          </div>
        </Profile>
      </ProfileContainer>
    </Container>
  );
};

export default MenuHeader;
