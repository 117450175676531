import {WRONG, TEXT} from '@etiquette-ui/colors';
import styled from 'styled-components';

export const Body = styled.div`
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    margin: 0;
    color: black;
    width: 100%;
`;

export const ActionsContainerForm = styled.form`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ActionsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Info = styled.p`
    font-size: 0.75rem;
    color: ${TEXT};
    text-align: center;
`;

export const TextLink = styled.p`
    font-size: 0.75rem;
    text-align: center;
    word-wrap: normal;
`;

export const Text = styled.p`
    font-size: 1rem;
    color: ${TEXT};
    text-align: center;
`;

export const ErrorText = styled.p`
    font-size: 0.75rem;
    color : ${WRONG};
    text-align: center;
    margin: 0;
`;