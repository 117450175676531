import { takeEvery, put, all, call, select } from 'redux-saga/effects';
import { getHighlightedDates } from 'resources/api';
import Types from './types';
import { getMonthInterval } from 'utils/date';
import dateSelector from './selector';
import { processError } from 'state/utils';

function* fetchStartAsync() {
    try {
        const date = yield select(dateSelector);
        const {start, end} = getMonthInterval(date.displayedDate);
        const res = yield getHighlightedDates({start, end });
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
    yield takeEvery(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
    ]);
}
