export const OPENTABLE_RID = process.env.REACT_APP_OPENTABLE_RID;
export const STRIPE_CODE = process.env.REACT_APP_STRIPE_CODE;
export const RESERVATION_HOTEL_NAME = process.env.REACT_APP_RESERVATION_HOTEL_NAME;
export const GROUP_ID = process.env.REACT_APP_GROUP_ID;
export const MANAGERS_DIVISIONS_AND_ROLES = process.env.REACT_APP_MANAGERS_DIVISIONS_AND_ROLES;
export const APP_ID = process.env.REACT_APP_APP_ID;
export const LOGGER_URL = process.env.REACT_APP_LOGGER_URL || "https://api.adminsite.test.appsinti.com/logger";

export const RESERVATION_PER_PERSON_COST_DAY = process.env.REACT_APP_RESERVATION_PER_PERSON_COST_DAY;
export const RESERVATION_PER_PERSON_COST_EVENING = process.env.REACT_APP_RESERVATION_PER_PERSON_COST_EVENING;
export const RESERVATION_COST_CHANGE_HOUR = process.env.REACT_APP_RESERVATION_COST_CHANGE_HOUR;

export const DEFAULT_LINK_EXPIRATION = process.env.REACT_APP_DEFAULT_LINK_EXPIRATION;

export const ACCESS_LIST = {
    RESERVATION: 'register_reservations',
}

export const ACCESS_USER_TYPES = {
    ROOT: 'root',
    GLOBAL: 'global',
    REGULAR: 'regular',
}