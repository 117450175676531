import styled from 'styled-components';
import {BG} from '@etiquette-ui/colors';

export const Nav = styled.nav`
  display: flex;
  justify-content: start;

  @media( max-width: 1000px ){
    position: fixed;
    z-index: 5;
    top: auto;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;

    padding-left: 0px;

    border-radius: 20px 20px 0px 0px;
    background-color: ${BG};
  }

  @media( min-width: 1124px ){
    padding-left: 2rem;
  }
`;

export const ItemContainer = styled.div`
  max-width: 180px;
  padding: 10px;
  display: flex;
  justify-content: center;

  @media( max-width: 1000px ){
    padding: 10px 3px 10px 3px;
    margin: 0px;

    min-width: 33.333333%;
    max-width: 33.333333%;
    flex: 0 0 33.333333%;

    * {
      font-size: 12px!important;
      text-align: center;
    }
  }
`;

export const Item = styled.div`
  display: inline-block;

  & a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;

    opacity: .7;

    &.current {
      font-weight: 600;
      opacity: 1;
    }
  }

  .icon {
    max-width: 22px;
    margin: auto;
    margin-bottom: 0px;
  }

  @media( min-width: 1000px ){
    & > a {
      opacity: 1;
    }
    .icon{
      display: none;
    }
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;

  @media( max-width: 768px ){
    display: none;
  }
`;

export const Indicator = styled.div`
  margin-top: 3px;
  width: 30px;
  height: 3px;
  background-color: #388cff;
  border-radius: 10px;
`;
