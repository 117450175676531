import React, { useState } from 'react';
import useReservations from 'state/reservations';
import useHighlightedDates from 'state/highlighted';
import useI8n from 'i18n/useI18n';
import {PRIMARY} from '@etiquette-ui/colors';
import IconButton from 'components/Buttons/IconButton';
import styled from 'styled-components';
import useDate from 'state/date';
import { isSummary } from './utils';

const Button = styled(IconButton)`
    margin-left: 15px;
    order: 2;
    p {
        color: ${PRIMARY};
        display: none;
    }

    @media( min-width: 768px ){
        order: 4;
        min-width: 130px;
        justify-content: center;

        p {
        display: block;
        }
    }
`;

const UpdateButton = ({ pathname }) => {
    const [reservations, dispatcher] = useReservations();
    const [date] = useDate();
    const [, highlightedDispatcher] = useHighlightedDates();

    const onUpdate = () => {
        highlightedDispatcher.fetchStart();
        if (isSummary(pathname)) {
            dispatcher.fetchAll();
            return;
        }

        dispatcher.fetchStart({ start: date });
    };

    const { translate } = useI8n();

    return (
        <Button
            onClick={onUpdate}
            icon={'update'}
            text={translate('Update Data')}
            loading={reservations.isFetching}
        />
    );
};

export default UpdateButton;
