import React from 'react';
import styled from 'styled-components';
import CommonInfo from 'pages/home/pages/Reservations/alert/components/CommonInfo';
import CommonInfoElement from '../components/CommonInfo/CommonInfoElement';
import ConfirmedIcon from 'resources/img/confirmed-icon.svg';
import AmountIcon from 'resources/img/confirmed-link-icon.svg';
import useI8n from 'i18n/useI18n';
import { ColorButton, AcceptButton, AltTransparentButton } from 'components/Buttons';
import { INPUTS } from '@etiquette-ui/colors';
import { useState } from 'react';
import { ActionsContainer } from '../components/styles';
import { formatMoney } from 'utils/text';
import { needsCharge, chargePending, chargeSuccess } from 'utils/status';
import { LOCAL_STATUS_CODE, STATUS_CODE } from 'resources/constants/status';
import useUsers from 'state/users';

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 48px;
`;

const Entry = ({ dispatcher, reservation, next, changeStep, currentStep = null, onClose }) => {
  const { translate } = useI8n();
  const [isChanging, setChanging] = useState(false);
  const [users] = useUsers();
  const link = reservation.link;

  const updateStatus = (statusCode) => {
    if (link.statusCode !== statusCode) {
      dispatcher.updateLink({
        code: link.code,
        statusCode: statusCode,
      });
    }
    setChanging(false);
  };

  const getManagerName = (managerId) => {
    const manager = users.managers.find(({ id }) => id == managerId);
    
    return manager ? `${manager.name} ${manager.lastName}` : '';
  }

  return currentStep === 0 || currentStep === null ? (
    <>
      <CommonInfo reservation={reservation} />
      {(reservation.localStatusCode === LOCAL_STATUS_CODE.CONFIRMED || isChanging) && (
        <>
          <CommonInfoElement source={ConfirmedIcon} data={<>{translate('Has the customer attended?')}</>} />
          {reservation.localStatusCode === LOCAL_STATUS_CODE.CONFIRMED || isChanging ? (
            <ButtonsContainer>
              <ColorButton onClick={() => updateStatus('hasattended')} color={INPUTS} style={{ maxWidth: '70px', padding: '10px 0', color: 'black' }}>
                {translate('Yes')}
              </ColorButton>
              <ColorButton
                onClick={() => updateStatus('hasnotattended')}
                color={INPUTS}
                style={{ maxWidth: '70px', marginLeft: '15px', padding: '10px 0', color: 'black' }}
              >
                {translate('No')}
              </ColorButton>
            </ButtonsContainer>
          ) : null}
        </>
      )}
      {reservation.localStatusCode !== LOCAL_STATUS_CODE.CONFIRMED && !isChanging && (
        <CommonInfoElement
          source={ConfirmedIcon}
          data={reservation.localStatusCode === LOCAL_STATUS_CODE.ATTENDED ? translate('Customer has attended') : translate('Customer has not attended')}
          action={
            reservation.localStatusCode === LOCAL_STATUS_CODE.ATTENDED || needsCharge(reservation) ? (
              <AltTransparentButton onClick={() => setChanging(true)} style={{ maxWidth: '100%', padding: '10px 0' }}>
                {translate('Change')}
              </AltTransparentButton>
            ) : null
          }
        />
      )}
      {reservation.localStatusCode !== LOCAL_STATUS_CODE.CONFIRMED && reservation.localStatusCode !== LOCAL_STATUS_CODE.ATTENDED && reservation.link.statusCode !== STATUS_CODE.CHARGED && !reservation.link.electedNoCharge && (
        <ActionsContainer>
          <AcceptButton onClick={next} style={{ width: '70%' }} text={translate('Add Charge')} />
          <AltTransparentButton onClick={() => changeStep(2)} style={{ maxWidth: '258px', marginTop: '10px' }}>
            {translate('Manager Approved No Charge')}
          </AltTransparentButton>
        </ActionsContainer>
      )}
      {!chargePending(reservation) && chargeSuccess(reservation) && (
        <CommonInfoElement source={AmountIcon} data={`${formatMoney(reservation.link.charge.amount / 100)} ${translate('charged')}`} />
      )}
      {!!reservation.link.electedNoCharge && (
        <CommonInfoElement
          source={AmountIcon}
          data={translate('Manager Approved No Charge')}
          altData={getManagerName(reservation.link.electedNoCharge.id)}
        />
      )}
    </>
  ) : null;
};

export default Entry;
