import React, { useContext } from 'react';
import useReservations from 'state/reservations';
import { useEffect } from 'react';
import CardHeader from './components/CardHeader';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Schedule from './pages/Schedule';
import Summary from './pages/Summary';
import useHighlightedDates from 'state/highlighted';
import { SearchProvider } from './providers/search';
import { FilterProvider } from './providers/filter';
import { ClockContext } from 'providers/clock';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import { isExpired } from 'utils/status';
import CardContainer from 'containers/CardContainer';
import CardLayout from 'containers/CardLayout';
import { useAlert } from 'alert';
import hookAlertTypes from 'alert/types';
import useI18n from 'i18n/useI18n';

const Reservations = ({ location }) => {
    const { path, url } = useRouteMatch();
    const [reservations, dispatcher] = useReservations();
    const [, highlightedDispatcher] = useHighlightedDates();
    const [subscribe, unsusbcribe] = useContext(ClockContext);
    const alert = useAlert();
    const { translate } = useI18n();

    useEffect(() => {
        dispatcher.fetchStart();
        highlightedDispatcher.fetchStart();
    }, []);

    const checkTimers = (d) => () => {
        const dates = { ...d };
        let statusDidChange = false;
        for (const day of Object.values(dates)) {
            let count = 0;
            for (const rsv of day) {
                if (rsv.localStatusCode !== LOCAL_STATUS_CODE.WAITING || !isExpired(rsv)) {
                    count++;
                    continue;
                }
                day[count].localStatusCode = LOCAL_STATUS_CODE.EXPIRED;
                statusDidChange = true;
                count++;
            }
        }
        if (statusDidChange) {
            dispatcher.updateAll(dates);
            alert({
                type: hookAlertTypes.INFO,
                config: {
                    title: translate('Link Expired'),
                    body: translate("Review expired links and contact customer if required"),
                    buttonText: translate('OK'),
                },
            })
                .then(() => {})
                .catch(() => {});
        }
    };

    useEffect(() => {
        const check = checkTimers(reservations.dates);
        subscribe(check);
        return () => {
            unsusbcribe(check);
        };
    }, [reservations.dates]);

    return (
        <SearchProvider>
            <FilterProvider>
                <CardContainer>
                    <CardLayout>
                        <CardHeader location={location} url={url} />
                        <Switch>
                            <Route exact path={path}>
                                <Redirect to={`${url}/summary`} />
                            </Route>
                            <Route path={`${path}/summary`} component={Summary} />
                            <Route path={`${path}/schedule`} component={Schedule} />
                        </Switch>
                    </CardLayout>
                </CardContainer>
            </FilterProvider>
        </SearchProvider>
    );
};

export default Reservations;
