import { DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  readed: false,
  isFetching: false,
  error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_START: return { ...state, isFetching: true };
    case Types.FETCH_SUCCESS: return { ...state, list: action.payload, isFetching: false, error: { ...DEFAULT_NO_ERROR }  };
    case Types.FETCH_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };

    case Types.SET_READED: {
      return { ...state, readed: action.payload };
    }

    case Types.SET_ALL_NOTIFICATIONS_READED: {
      const list = state.list.map(i => ({ ...i, isRead: true }));

      return { ...state, list }
    }

    case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

    default:
      return state;
  }
};

export default reducer;
