import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';

const Create = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.WAITING} structure={structure} title={'waiting_table_title'} htmlId={'waiting-table-container'} />;
};

export default Create;
