import Axios from "axios";
import Authorization from './auth';
import crudder from './crudder';
import { executeCall } from './utils';

const consumer = (domain, resource) => {

    const defaultCrudder = crudder(domain)(resource);
  
    const url = `${domain}/${resource}`;
  
    const headers = Authorization;
  
    return {
      ...defaultCrudder,
      getAll: (family) => executeCall(() => Axios.get(url + `/${family}`, { headers })),
      getOne: (id) => executeCall(() => Axios.get((url + '/view/' + id, { headers }))),
    };
  }
  
  export default consumer;