import Types from "./types";

export default class ProductsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchPast = () => this.dispatch({ type: Types.FETCH_PAST, payload: {} });

    fetchFuture = () => this.dispatch({ type: Types.FETCH_FUTURE, payload: {} });

    fetchAll = () => this.dispatch({ type: Types.FETCH_ALL, payload: {} });

    fetchStart = payload => this.dispatch({ type: Types.FETCH_START, payload });

    updateAll = payload => this.dispatch({ type: Types.UPDATE_ALL, payload });

    createLink = payload => this.dispatch({ type: Types.CREATE_LINK_START, payload });

    updateLink = payload => this.dispatch({ type: Types.UPDATE_LINK_START, payload });

    updateLinkCharge = payload => this.dispatch({ type: Types.UPDATE_CHARGE_START, payload });

    charge = payload => this.dispatch({ type: Types.CHARGE_START, payload });

    cancel = (uid) => this.dispatch({ type: Types.CANCEL_START, payload: uid })

    updateCharge = (payload) => this.dispatch({ type: Types.CHARGE_SUCCESS, payload });

    loadOne = (payload) => this.dispatch({ type: Types.FETCH_LOADONE_START, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}