import React from 'react';
import hookAlertTypes from 'alert/types';
import { AcceptLink } from 'components/Buttons';
import { getAlertBody } from 'pages/home/pages/Reservations/alert/utils';

const ViewDetails = ({ globalState, item }) => {
  return (
    <AcceptLink
      style={{ alignSelf: 'center', textAlign: 'center' }}
      onClick={() => {
        globalState.config
          .alert({
            type: hookAlertTypes.CUSTOM,
            config: {
              props: {
                id: item.id,
              },
              body: getAlertBody(item),
            },
          })
          .catch((e) => {});
      }}
    >
      {globalState.config.translate('View Details')}
    </AcceptLink>
  );
};

export default ViewDetails;
