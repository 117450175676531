import Axios from "axios";
import { getToday } from "utils/date";
import Authorization from './auth';
import { executeCall } from './utils';

const pendingCharges = (domain, resource, withAuth = true) => {
    const url = `${domain}/${resource}`;

    const headers = withAuth ? Authorization : {};

    return {
        list: (start, end) => {
            const dateStart = start ?? getToday();
            const dateEnd = end ?? dateStart;

            return executeCall(() => Axios.get(`${url}/${dateStart}/${dateEnd}`, { headers }))
        },
    };
};

export default pendingCharges;