import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';
import { TABLE_MODES } from '../../utils';

const Attended = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.ATTENDED} structure={structure} htmlId={'attended-table-container'} mode={TABLE_MODES.PAST}/>;
};

export default Attended;
