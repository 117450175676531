import React, { useEffect, useRef } from 'react';
import { extractTime, extractTimeHH, extractTimemm, extractYearDay } from 'utils/date';
import useReservations from 'state/reservations';
import useDate from 'state/date';
import { statusColors } from 'resources/constants/colors';
import ScheduleElements from './ScheduleElement';
import useI8n from 'i18n/useI18n';
import { isActionRequired } from 'utils/status';
import { ScheduleMain, ScheduleGrid, ColumnHours, TimeSlotContainerHours, ColumnDates, TimeSlotContainer, TimeSlotRow, Time, TimeAppointmens } from './styles';
import { useState } from 'react';
import { useContext } from 'react';
import { FilterContext, FILTER_DEFAULT_VALUE } from 'pages/home/pages/Reservations/providers/filter';
import searchFilter from 'pages/home/pages/Reservations/searchFilter';

const FIX_HOURS = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
const QUARTILES = ['00', '15', '30', '45'];

const sortColumn = (arr) => {
  const matrix = [];
  arr.forEach((element) => {
    const HHmm = extractTime(element.scheduledTime);
    const HH = extractTimeHH(element.scheduledTime);
    const mm = extractTimemm(element.scheduledTime);
    const mmNumber = parseInt(mm);
    const insertElements = (HHmm, element) => (matrix[HHmm] ? matrix[HHmm].push(element) : (matrix[HHmm] = [element]));

    if (mm !== '00' && mm !== '15' && mm !== '30' && mm !== '45') {
      if (mmNumber < 15) insertElements(`${HH}00`, element);
      if (mmNumber > 15 && mmNumber < 30) insertElements(`${HH}15`, element);
      if (mmNumber > 30 && mmNumber < 45) insertElements(`${HH}30`, element);
      if (mmNumber > 45) insertElements(`${HH}45`, element);
    } else {
      insertElements(HHmm, element);
    }
  });
  return matrix;
};

const ScheduleTable = ({ search }) => {
  const [date] = useDate();
  let setIntervalID = useRef(null);
  const { translate } = useI8n();

  useEffect(() => {
    const tick = () => {
      const today = new Date();
      const HH = extractTimeHH(today);
      const mm = extractTimemm(today);
      const mmNumber = parseInt(mm);
      const HHNumber = parseInt(HH);
      const nexHH = HHNumber + 1;
      const insertLineTime = (HHmm, backgroundStyle) => {
        const divElement = document.getElementById(`${HHmm}`);
        return divElement !== null && (divElement.style.background = backgroundStyle);
      };
      const blackLine = `linear-gradient(#fff, #fff 50%, #000 50%, #000 51%, #fff 51%, #fff 100%)`;
      const white = 'white';

      mmNumber <= 15 ? (
        <>
          {' '}
          {insertLineTime(`${HH}15`, blackLine)}; {insertLineTime(`${HH}00`, white)};{' '}
        </>
      ) : (
        insertLineTime(`${HH}15`, white)
      );
      mmNumber > 15 && mmNumber <= 30 ? insertLineTime(`${HH}30`, blackLine) : insertLineTime(`${HH}30`, white);
      mmNumber > 30 && mmNumber <= 45 ? insertLineTime(`${HH}45`, blackLine) : insertLineTime(`${HH}45`, white);
      mmNumber > 45 ? insertLineTime(`${nexHH}00`, blackLine) : insertLineTime(`${nexHH}00`, white);
    };

    const whiten = () => {
      const white = 'white';
      const fixHours = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
      const quartiles = ['00', '15', '30', '45'];
      fixHours.forEach((hour) => {
        quartiles.forEach((quartil) => {
          const divElement = document.getElementById(`${hour}${quartil}`);
          divElement !== null && (divElement.style.background = white);
        });
      });
    };

    const currentDay = new Date();
    const isDateCurrentDay = extractYearDay(date) === extractYearDay(currentDay);

    isDateCurrentDay ? (setIntervalID.current = setInterval(tick, 5000)) : clearInterval(setIntervalID.current);

    !isDateCurrentDay && whiten();
  }, [date]);

  const [reservations] = useReservations();
  const { filter } = useContext(FilterContext);
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [matrix, setMatrix] = useState([]);

  useEffect(() => {
    if (!reservations.dates[date]) return;
    const filtered = searchFilter(search, reservations.dates[date], translate).filter(
      (reservation) => filter?.value === FILTER_DEFAULT_VALUE || reservation.localStatusCode === filter?.value
    );
    setFilteredReservations(filtered);
  }, [reservations.dates, search, translate, filter, date]);

  useEffect(() => {
    setMatrix(sortColumn(filteredReservations));
  }, [filteredReservations]);

  return (
    <ScheduleMain>
      <ScheduleGrid>
        <ColumnHours>
          {FIX_HOURS.map((hour) => {
            return (
              <TimeSlotContainerHours className="timeContainer" key={hour}>
                {QUARTILES.map((quartil) => {
                  const index = `${hour}${quartil}`;
                  const matrixRow = matrix[index];
                  const handler = (matrixRow || quartil === '00') && true;
                  return (
                    handler && (
                      <TimeSlotRow key={`${hour}${quartil}`}>
                        <Time>{`${hour}:${quartil}`}</Time>
                      </TimeSlotRow>
                    )
                  );
                })}
              </TimeSlotContainerHours>
            );
          })}
        </ColumnHours>
        <ColumnDates>
          {FIX_HOURS.map((hour) => {
            return (
              <TimeSlotContainer className="timeContainer" key={hour}>
                {QUARTILES.map((quartil) => {
                  const index = `${hour}${quartil}`;
                  const matrixRow = matrix[index];
                  const handler = (matrixRow || quartil === '00') && true;
                  return (
                    handler && (
                      <TimeSlotRow id={`${hour}${quartil}`} key={`${hour}${quartil}`}>
                        <TimeAppointmens>
                          {matrixRow &&
                            matrixRow.map((item, i) => {
                              const { background, foreground, border } = statusColors(isActionRequired(item));
                              return (
                                <ScheduleElements
                                  key={`${item.guestId}_${i}`}
                                  element={item}
                                  backgroundColor={background}
                                  color={foreground}
                                  border={border}
                                  statusText={item.localStatusCode}
                                />
                              );
                            })}
                        </TimeAppointmens>
                      </TimeSlotRow>
                    )
                  );
                })}
              </TimeSlotContainer>
            );
          })}
        </ColumnDates>
      </ScheduleGrid>
    </ScheduleMain>
  );
};

export default ScheduleTable;
