import { DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
    managers: [],
    all: [],
    isFetching: false,
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, isFetching: true }        
        case Types.FETCH_SUCCESS: return { ...state, [action.payload.key]: action.payload.list, isFetching: false, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } }

        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
}

export default reducer