import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'resources/constants/colors';
import HomePage from 'pages/home';
import { AlertServiceProvider } from './alert';
import { SnacksServiceProvider } from './snackbar';
import Login from 'pages/login';
import { I8nProvider } from 'i18n/provider';
import { ClockProvider } from 'providers/clock';
import Socket from './Socket';
import CopyAlertProvider from 'providers/copy';
import PrivateRoute from 'containers/PrivateRoute';
import ErrorManagement from 'components/ErrorManagement';
import EtiquetteAlertsProvider from '@etiquette-ui/alert-dialog';
import useDisputes from 'state/disputes';
import useHighlightedDates from 'state/highlighted';
import useNomenclators from 'state/nomenclators';
import useNotifications from 'state/notifications';
import usePendingCharges from 'state/pendingCharges';
import useReservations from 'state/reservations';
import useUsers from 'state/users';
import AccessProvider from 'providers/access';

import { LOGGER_URL } from 'resources/constants/config';
import Logger from '@appsinti/logger';

Logger.setUrl(LOGGER_URL);

const App = () => {

  const [disputes, disputesDispatcher] = useDisputes();
  const [highlighted, highlightedDispatcher] = useHighlightedDates();
  const [nomenclators, nomenclatorsDispatcher] = useNomenclators();
  const [notifications, notificationsDispatcher] = useNotifications();
  const [pendingCharges, pendingChargesDispatcher] = usePendingCharges();
  const [reservations, reservationsDispatcher] = useReservations();
  const [users, usersDispatcher] = useUsers();

  return (
    <ThemeProvider theme={theme}>
      <AccessProvider>
        <ClockProvider>
          <I8nProvider>
            <CopyAlertProvider>
              <SnacksServiceProvider>
                <AlertServiceProvider>
                  <EtiquetteAlertsProvider>
                    <Socket />
                    <ErrorManagement states={{
                      disputes: { state: disputes.error, resetError: disputesDispatcher.resetError },
                      highlighted: { state: highlighted.error, resetError: highlightedDispatcher.resetError },
                      nomenclators: { state: nomenclators.error, resetError: nomenclatorsDispatcher.resetError },
                      notifications: { state: notifications.error, resetError: notificationsDispatcher.resetError },
                      pendingCharges: { state: pendingCharges.error, resetError: pendingChargesDispatcher.resetError },
                      reservations: { state: reservations.error, resetError: reservationsDispatcher.resetError },
                      users: { state: users.error, resetError: usersDispatcher.resetError },
                    }} />
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <PrivateRoute path="/" component={HomePage} />
                    </Switch>
                  </EtiquetteAlertsProvider>
                </AlertServiceProvider>
              </SnacksServiceProvider>
            </CopyAlertProvider>
          </I8nProvider>
        </ClockProvider>
      </AccessProvider>
    </ThemeProvider>
  );
};

export default App;
