import React, { useEffect, useState, useContext } from 'react';
import { getTimeFromExpiration, formatTime } from 'utils/time';
import { ClockContext } from 'providers/clock';
import useI8n from 'i18n/useI18n';
import { Timer } from 'pages/home/pages/Reservations/components/TimerComponent';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CustomTimer = styled(Timer)`
  font-weight: 500;
`;

const CancelledTimerComponent = ({ reservation, className }) => {
  const [time, setTime] = useState(0);
  const [subscribe, unsusbcribe] = useContext(ClockContext);
  const { translateSeconds } = useI8n();

  useEffect(() => {
    subscribe(tick);
    return () => {
      unsusbcribe(tick);
    };
  }, []);

  const tick = () => {
    setTime(getTimeFromExpiration(reservation));
  };

  return (
    <Container>
      <CustomTimer hasAlert={false}>{`${formatTime(time)} ${translateSeconds(time)}`}</CustomTimer>
    </Container>
  );
};

export default CancelledTimerComponent;
