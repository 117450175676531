import { API } from '../constants/urls';
import reservations from './reservations';
import pendingCharges from './pendingCharges';
import nomenclator from './nomenclator';
import notifications from './notifications';
import { executeCall } from './utils';
import Axios from "axios";
import Authorization from './auth';
import client from './client';
import disputes from './disputes';
import { GROUP_ID } from 'resources/constants/config';
import authCrudder from './auth-crud';

export const reservationsAPI = reservations(API, "reservations/admin");
export const clientsAPI = client(API, "reservations/client");
export const pendingChargesAPI = pendingCharges(API, "reservations/admin/payment");
export const getHighlightedDates = ({ start, end }) => executeCall(() => Axios.get(`${API}/reservations/admin/schedule/${start}/${end}`, { headers: Authorization, timeout: 240000 }));
export const getUsersOfGroup = ({ division, role }) => executeCall(() => Axios.get(`${API}/security/user/ofgroup/${GROUP_ID}/${!!division ? division : '0'}/${!!role ? role : '0'}`, { headers: Authorization }));
export const nomenclatorAPI = nomenclator(API, "reservations/admin/nomenclator");
export const notificationsAPI = notifications(API, "reservations/admin/notification");
export const disputesAPI = disputes(API, "reservations/admin/dispute");
export const authAPI = authCrudder(API, "auth/access-list");