import { all, call } from 'redux-saga/effects';
import reservations from './reservations/sagas';
import date from './date/sagas';
import highlighted from './highlighted/sagas';
import nomenclators from './nomenclators/sagas';
import notifications from './notifications/sagas';
import pendingCharges from './pendingCharges/sagas';
import disputes from './disputes/sagas';
import users from './users/sagas';

export default function* rootSaga() {
  yield all([
    call(reservations), 
    call(date), 
    call(highlighted), 
    call(nomenclators),
    call(notifications),
    call(pendingCharges),
    call(disputes),
    call(users),
  ]);
}
