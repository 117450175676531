import React, { useEffect, useState } from 'react';
import useI8n from 'i18n/useI18n';
import IconButton from 'components/Buttons/IconButton';
import { PRIMARY } from "@etiquette-ui/colors";
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import usePendingCharges from 'state/pendingCharges';
import { formatMoney } from 'utils/text';
import { getFormattedDate } from 'utils/date';

const LinkText = styled(CSVLink)`
  color: ${PRIMARY};
  padding-left: 10px;
  font-size: 0.875rem;
  font-weight: 700;
  user-select: none;
  text-decoration: none;

  max-width: 40%;
  flex: 0 0 40%;

  @media( min-width: 768px ){
    max-width: 20%;
    flex: 0 0 20%;
  }
`;

const Button = styled(IconButton) `
  p {
    color: ${PRIMARY};
  }
`;

const headers = [
  { label: 'Date and Time', key: 'dateTime' },
  { label: 'Last, First Name', key: 'client' },
  { label: '#ppl', key: 'ppl' },
  { label: 'Total', key: 'total' },
  { label: 'Rejected', key: 'rejected' },
  { label: 'Collected', key: 'collected' },
  { label: 'Status', key: 'status_code' },
  { label: 'Last Attempt', key: 'lastAttempt' },
];

const ExportButton = () => {
  const [charges] = usePendingCharges();
  const [data, setData] = useState([]);
  const { translate, formatDate } = useI8n();

  useEffect(() => {
    const list = charges.list.map((item) => ({
      dateTime: formatDate(`${item.date}T${item.time}`),
      client: item.client,
      ppl: item.ppl,
      total: formatMoney(item.total / 100),
      rejected: item.status_code !== 'succeeded' ? formatMoney(item.total/100) : '',
      collected: item.status_code === 'succeeded' ? formatMoney(item.total/100) : '',
      status_code: translate(item.status_code),
      lastAttempt: getFormattedDate(item.lastAttempt),
    }));
    setData(list);
  }, [charges.list, translate]);

  return (
      <LinkText headers={headers} data={data} filename={'activity.csv'} target="_blank">
        <Button icon={'export'} text={translate('Export report')} />
      </LinkText>
  );
};

export default ExportButton;
