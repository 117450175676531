import React from "react";
import BaseDialog from "./BaseDialog";
import styled from 'styled-components';
import { Text } from 'components/Text';
import { ColorButton } from 'components/Buttons';

const Section = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Actions = ({ onClose, buttonText }) => {
  return (
    <Section>
      <ColorButton onClick={onClose}>{buttonText}</ColorButton>
    </Section>
  );
};


const InfoDialog = ({ open, onClose, config }) => {

  return (
    <BaseDialog
      open={open}
      title={config.title}
      header={config.header}
      onClose={onClose}
      dismissible={true}
      isResponsive={true}
      actions={<Actions onClose={onClose} buttonText={config.buttonText} />}
    >
      <Text style={{textAlign: 'center', marginBottom: '20px'}}>{config.body}</Text>
      {config.foot && <Text style={{textAlign: 'center', fontSize: '0.75rem'}}>{config.foot}</Text>}
    </BaseDialog>
  );
};

export default InfoDialog;