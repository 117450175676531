import React, { useEffect, useRef, useState } from 'react';
import BaseDialog from 'alert/components/BaseDialog';
import Header from '../components/Header';
import InfoStep from '../components/InfoStep';
import GenerateStep from './GenerateStep';
import useReservations from 'state/reservations';
import useNomenclators from 'state/nomenclators';
import useStep from 'hooks/useStep';
import { needsLink } from 'utils/status';

const GenerateLink = ({ id, open, onSubmit, onClose }) => {
  const [reservations,, utils] = useReservations();
  const [data, nomenclatorsDispatcher] = useNomenclators();
  const [reservation, setReservation] = useState(null);
  const bodyRef = useRef(null);
  const [bodyRefVisible, setBodyRefVisible] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(null);
  const [step, previous, next] = useStep();

  useEffect(() => {
    const reservation = utils.findOne(id);
    setReservation(reservation);
  }, [reservations.dates]);

  useEffect(() => {
    nomenclatorsDispatcher.select(null);
    nomenclatorsDispatcher.confirm(false);
  }, []);

  useEffect(() => {
    if (!bodyRefVisible) return;
    if (!bodyRef?.current) return;
    setBodyHeight(bodyRef.current.clientHeight);
  }, [bodyRefVisible]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      dismissible={false}
      header={reservation && <Header reservation={reservation} id={id} withBackButton={step !== 0} onBackPressed={previous} previous={previous} loading={reservations.isFetching}/>}
    >
      <InfoStep
        reference={(el) => {
          bodyRef.current = el;
          setBodyRefVisible(!!el);
        }}
        reservation={reservation}
        id={id}
        onClose={onClose}
        next={next}
        currentStep={step}
        actionActive={(!!data.reservationType?.selected && !!data.reservationType?.confirmed) || (reservation && !needsLink(reservation))}
      />
      <GenerateStep
        id={id}
        reservation={reservation}
        bodyHeight={bodyHeight}
        currentStep={step}
        previous={previous}
        onClose={onClose}
      />
    </BaseDialog>
  );
};

export default GenerateLink;
