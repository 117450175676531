import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';

const Cancelled = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.CANCELLED} structure={structure} htmlId={'cancelled-table-container'} />;
};

export default Cancelled;
