import useI18n from 'i18n/useI18n';
import React, { useState } from 'react';
import Modal from 'simple-react-modal';
import styled from 'styled-components';

const ModalContainerStyles = {
  display: 'flex',
  fontFamily: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
};

const ModalStyles = (backgroundColor) => ({
  width: '100%',
  padding: '0',
  overflow: 'visible',
  maxWidth: '600px',
  margin: '0',
  height: '80px',
  maxHeight: '80px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: !!backgroundColor ? backgroundColor : '#0F191B'
});

const Content = styled.div`
  padding: 16px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin: 10px 0;
  color: white;
`;

const CopyAlertContext = React.createContext();

const CopyAlertProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const { translate } = useI18n();

  const show = (text, backgroundColor = '', timeout = 3000) => {
    setText(text);
    setBackgroundColor(backgroundColor);
    setVisible(true);
    setTimeout(()=>{setVisible(false); setBackgroundColor('');}, timeout);
  };

  return (
    <>
      <CopyAlertContext.Provider value={show}>{children}</CopyAlertContext.Provider>
      <Modal show={visible} containerStyle={ModalStyles(backgroundColor)} style={ModalContainerStyles}>
        <Content>
          <Title>{translate(text)}</Title>
        </Content>
      </Modal>
    </>
  );
};

export const useCopyAlert = () => React.useContext(CopyAlertContext);

export default CopyAlertProvider;
