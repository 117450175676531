const TYPE = `[NOTIFICATIONS]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    READED_START: `${TYPE} READED START`,

    SET_READED: `${TYPE} SET READED`,
    SET_ALL_NOTIFICATIONS_READED: `${TYPE} SET ALL NOTIFICATIONS READED`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;