import Axios from 'axios';
import Authorization from './auth';
import { executeCall } from './utils';

const nomenclator = (domain) => (resource, withAuth = true) => {
    const url = `${domain}/${resource}`;

    const headers = withAuth ? Authorization : {};

    return {
      getAll: () => executeCall(() => Axios.get(url, { headers })),
      getOne: (id) => executeCall(() => Axios.get(url + '/' + id, { headers })),
      create: (data) => executeCall(() => Axios.post(url, data, { headers })),
      update: (data) => executeCall(() => Axios.patch(url, data, { headers })),
      delete: (options) => {
        const data = {};
        Object.entries(options).forEach(([key, value]) => (data[key] = value));
        executeCall(() =>
          Axios({
            method: 'delete',
            url,
            headers,
            data,
          })
        );
      },
    };
  };

export default nomenclator;
