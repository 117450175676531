import React, { useEffect, useState } from 'react';
import useReservations from 'state/reservations';
import { needsLink, isExpired } from 'utils/status';
import { ColorButton } from 'components/Buttons';
import TimeInput from 'components/Inputs/TimeInput';
import { Body, ActionsContainerForm, Info, ErrorText, Text } from '../components/styles';
import { millisecondsFromDate } from 'utils/time';
import useI8n from "i18n/useI18n";
import { DEFAULT_LINK_EXPIRATION } from 'resources/constants/config';

const GenerateStep = ({ id, currentStep, bodyHeight, reservation, previous, onClose }) => {
  const [reservationsData, dispatcher, utils] = useReservations();
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [expirationTime, setExpirationTime] = useState(DEFAULT_LINK_EXPIRATION);
  const [expirationInterval, setExpirationInterval] = useState(0);
  const { translate , expirations } = useI8n();


  useEffect(() => {
    setDisabled(reservationsData.isFetching);
  }, [reservationsData, utils]);

  const generateLink = (e) => {
    e.preventDefault();
    if (!expirationTime) {
      setError(translate('Please input an expiration time'));
      return;
    }

    onClose();
    dispatcher.createLink({ reservation, expirationTime: expirationTime * expirations[expirationInterval].multiplier });
    
  };

  const updateLink = (e) => {
    e.preventDefault();
    if (!expirationTime) {
        setError(translate('Please input an expiration time'));
        return;
    }
    const secondsFromSelector = expirationTime*expirations[expirationInterval].multiplier;
    const secondsToAdd = Math.floor(millisecondsFromDate(reservation.link.createdAt)/1000) + secondsFromSelector;

    previous();
    dispatcher.updateLink({code: reservation.link.code, expirationSeconds: secondsToAdd});
  };

  return currentStep === 1 ? (
    <Body style={{ minHeight: bodyHeight ? bodyHeight : '0px' }}>
      {!reservationsData.isFetching && reservation && (needsLink(reservation) || isExpired(reservation)) && (
        <>
          <Text style={{ fontWeight: '600', textAlign: 'left' }}>{needsLink(reservation) ? translate('Generate customer link') : translate('Add more expiration time')}</Text>
          <ActionsContainerForm onSubmit={needsLink(reservation) ? generateLink : updateLink}>
            <Info style={{ textAlign: 'left', width: '100%' }}>{needsLink(reservation) ? translate('Link expiration time') : translate('Added time')}</Info>
            <TimeInput
              onChange={(e) => setExpirationTime(e.target.value)}
              value={expirationTime}
              onChangeSelector={(e) => setExpirationInterval(e.target.value)}
              selectorValue={expirationInterval}
              selectorOptions={expirations}
            />
            {!!error && <ErrorText>{error}</ErrorText>}
            <ColorButton type="submit" color={disabled} style={{ disabled: disabled, marginTop: '10px' }}>
              {needsLink(reservation) ? translate('Generate customer link') : translate('Update customer link')}
            </ColorButton>
          </ActionsContainerForm>
        </>
      )}
    </Body>
  ) : null;
};

export default GenerateStep;
