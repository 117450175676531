import { takeLatest, put, all, call } from 'redux-saga/effects';
import Types from './types';
import GlobalTypes from '../globalTypes';

function* startDateChangeAsync({payload}) {
  try {
    yield put({ type: Types.CHANGE_DATE, payload });
    yield put({ type: GlobalTypes.DATE_DID_CHANGE })
  } catch (e) {
    yield put({ type: Types.FETCH_ERROR });
  }
}

function* startDateChange() {
  yield takeLatest(Types.DATE_WILL_CHANGE, startDateChangeAsync);
}

export default function* sagas() {
  yield all([
    call(startDateChange),
  ]);
}