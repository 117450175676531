import { takeLatest, takeEvery, put, all, call, select } from 'redux-saga/effects';
import { reservationsAPI, clientsAPI } from 'resources/api';
import Types from './types';
import GlobalTypes from '../globalTypes';
import dateSelector from '../date/selector';
import { getFormattedDate, getFormattedTime, removeDays, addDays } from 'utils/date';
import { parsePhoneNumber } from 'react-phone-number-input';
import nomenclatorsSelector from '../nomenclators/selector';
import { NOMENCLATORS_FAMILIES } from 'state/nomenclators/constants';
import { getLocalStatus } from 'utils/status';
import { processError } from 'state/utils';
import {getTranslation} from "../../i18n/i18n.helper";

const addLocalStatusCode = (map) => {
  const res = { ...map };
  for (const key of Object.keys(res)) {
    res[key] = res[key].map((rsv) => ({ ...rsv, localStatusCode: getLocalStatus(rsv) }));
  }
  return res;
};

function* fetchStartAsync({ payload: { start, end } = {} }) {
  try {
    const res = yield reservationsAPI.list(start, end);
    const formatted = addLocalStatusCode(res);
    yield put({ type: Types.FETCH_SUCCESS, payload: formatted });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeEvery(Types.FETCH_START, fetchStartAsync);
}

function* dateChangeAsync() {
  const date = yield select(dateSelector);
  yield put({ type: Types.FETCH_START, payload: { start: date } });
}

function* dateChange() {
  yield takeLatest(GlobalTypes.DATE_DID_CHANGE, dateChangeAsync);
}

const getValue = (data) => {
  return !!data.confirmed && !!data.selected ? data.selected.value : null;
};

function* createLinkAsync({ payload }) {
  const { reservation, expirationTime } = payload;
  const guest = reservation.guest;
  const parsedNumber = !!guest.phone ? parsePhoneNumber(guest.phone) : null;

  const nomenclators = yield select(nomenclatorsSelector);

  const nomenclatorIds = Object.values(NOMENCLATORS_FAMILIES).map((family) => getValue(nomenclators[family]));

  try {
    const data = {
      reservation: {
        date: getFormattedDate(reservation.scheduledTime),
        time: getFormattedTime(reservation.scheduledTime),
        personsQty: reservation.partySize,
        tableNumber: reservation.tableNumber ? reservation.tableNumber[0] : '',
        expirationSeconds: expirationTime,
        opentableId: reservation.id,
        opentableCreatedDate: reservation.createdDate,
      },
      nomenclators: nomenclatorIds.filter((value) => value !== null),
      client: {
        firstName: guest.firstName,
        lastName: guest.lastName,
        email: null,
        countryCode: parsedNumber ? `${parsedNumber.countryCallingCode}` : '',
        phone: parsedNumber ? parsedNumber.nationalNumber : '',
      },
    };
    yield reservationsAPI.create(data);
    yield put({ type: Types.CREATE_LINK_SUCCESS, payload: reservation.id });
    yield put({ type: Types.FETCH_START, payload: { start: getFormattedDate(reservation.scheduledTime) } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    alert(`${getTranslation('Generic error')}\n\n
          error: ${JSON.stringify(message)}`)
    yield put({ type: Types.CREATE_LINK_ERROR, payload: message });
  }
}

function* createLink() {
  yield takeEvery(Types.CREATE_LINK_START, createLinkAsync);
}

function* updateLinkAsync({ payload }) {
  const { code, expirationSeconds, statusCode } = payload;
  try {
    const res = yield reservationsAPI.update({
      reservation: { code, expirationSeconds, statusCode },
    });
    yield put({ type: Types.UPDATE_LINK_SUCCESS, payload: res.reservation });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_LINK_ERROR, payload: message });
  }
}

function* updateLink() {
  yield takeEvery(Types.UPDATE_LINK_START, updateLinkAsync);
}

function* updateChargeAsync({ payload }) {
  const { code, electedNoCharge } = payload;
  try {
    const res = yield reservationsAPI.update({
      reservation: { code, electedNoCharge },
    });
    yield put({ type: Types.UPDATE_LINK_SUCCESS, payload: res.reservation });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_LINK_ERROR, payload: message });
  }
}

function* updateCharge() {
  yield takeEvery(Types.UPDATE_CHARGE_START, updateChargeAsync);
}

function* chargeAsync({ payload }) {
  try {
    const res = yield reservationsAPI.charge(payload);
    yield put({ type: Types.CHARGE_SUCCESS, payload: { code: payload.code, charge: {...res.metadata, statusCode: res.status} } });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.CHARGE_ERROR, payload: message });
  }
}

function* charge() {
  yield takeEvery(Types.CHARGE_START, chargeAsync);
}

function* getDateRange(mode, daysOperation) {
  const currentDate = yield select(dateSelector);

  const newDate = daysOperation(currentDate + 'T00:00:00', 15);
  if(mode === 'past') {
    return {start: getFormattedDate(newDate), end: currentDate};
  }
  return {start: currentDate, end: getFormattedDate(newDate)};
}

function* fetchDateRange(mode, daysOperation) {
  try {
    yield put({ type: Types.IS_FETCHING });

    const {start, end} = yield getDateRange(mode, daysOperation);
    const res = yield reservationsAPI.list(start, end);
    const formatted = addLocalStatusCode(res);

    yield put({ type: Types.FETCH_SUCCESS, payload: formatted });

  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchPastAsync() {
  yield fetchDateRange('past', removeDays);
}

function* fetchPast() {
  yield takeLatest(Types.FETCH_PAST, fetchPastAsync);
}

function* fetchFutureAsync() {
  yield fetchDateRange('future', addDays);
}

function* fetchFuture() {
  yield takeLatest(Types.FETCH_FUTURE, fetchFutureAsync);
}

function* fetchAllAsync() {
  yield fetchDateRange('past', removeDays);
  yield fetchDateRange('future', addDays);
}

function* fetchAll() {
  yield takeLatest(Types.FETCH_ALL, fetchAllAsync);
}

function* cancelStartAsync({ payload }) {
  try {
    yield clientsAPI.cancel(payload);
    yield put({ type: Types.CANCEL_SUCCESS, payload });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.CHARGE_ERROR, payload: message });
  }
}

function* cancelStart() {
  yield takeLatest(Types.CANCEL_START, cancelStartAsync)
}


function* loadOneStartAsync({ payload }) {
  try {
    const res = yield reservationsAPI.loadOne(payload);
    yield put({ type: Types.FETCH_LOADONE_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_LOADONE_ERROR, payload: message });
  }
}

function* loadOneStart() {
  yield takeLatest(Types.FETCH_LOADONE_START, loadOneStartAsync)
}

export default function* sagas() {
  yield all([
    call(fetchStart),
    call(dateChange),
    call(createLink),
    call(updateLink),
    call(charge),
    call(fetchPast),
    call(fetchFuture),
    call(fetchAll),
    call(cancelStart),
    call(loadOneStart),
    call(updateCharge),
  ]);
}
