import React, { useEffect, useState } from 'react';
import { BoldText } from 'components/Text';
import ActionContainer from './components/ActionContainer';
import { CancelLink, AcceptLink } from 'components/Buttons';
import { MoneyInput, InputContainer, DescriptionInput, Icon } from 'components/Inputs';
import Errors from './components/Errors';
import Selector from 'components/Selector';
import DescriptionIcon from 'resources/img/description-icon.svg';
import { RESERVATION_COST_CHANGE_HOUR, RESERVATION_PER_PERSON_COST_DAY, RESERVATION_PER_PERSON_COST_EVENING } from 'resources/constants/config';
import useI18n from 'i18n/useI18n';

const reasons = [
  { id: 0, label: 'No-Show' },
  { id: 1, label: 'Other' },
];

const getPerPersonCost = (scheduledTime) => {
  const date = new Date(scheduledTime);
  const time = (date.getHours()*100)+date.getMinutes();
  const dayIndex = date.getDay();
  const costs = (time < RESERVATION_COST_CHANGE_HOUR ? RESERVATION_PER_PERSON_COST_DAY : RESERVATION_PER_PERSON_COST_EVENING).split('|');
  return costs[dayIndex] ?? 0;
}

const getReservationCost = (partySize, scheduledTime) => {
  const perPerson = getPerPersonCost(scheduledTime);

  const cost = partySize * perPerson;
  return isNaN(cost) ? 0 : cost;
};

const AddCharge = ({ dispatcher, reservation, previous, currentStep = null }) => {
  const [amount, setAmount] = useState(getReservationCost(reservation.partySize, reservation.scheduledTime));
  const [reason, setReason] = useState(0);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState([]);
  const [send, setSend] = useState(false);
  const { translate } = useI18n();

  const handleAccept = () => {
    setSend(true);

    if (errors.length === 0) {
      dispatcher.charge({
        date: reservation.link.date,
        code: reservation.link.code,
        amount: amountToCents(amount),
        reason: reasons[reason].label,
        description,
      });
      previous();
    }
  };

  useEffect(() => {
    const result = [];
    if (isNaN(amount)) result.push('Amount is not a valid number');
    if (amount < 10) result.push('Must charge at least 10 MXN');

    setErrors(result);
  }, [amount]);

  const amountToCents = (amount) => amount * 100;

  return currentStep === 1 ? (
    <div>
      <BoldText>{translate('Add customer charge.')}</BoldText>
      {send && <Errors errors={errors} />}
      <MoneyInput title="Amount" placeholder="0.00" value={amount} onChange={(e) => setAmount(e.target.value)} />
      <InputContainer>
        <Icon src={DescriptionIcon} />
        <Selector width={'100%'} selected={reason} handleOptions={(e) => setReason(e.target.value)} options={reasons} />
      </InputContainer>

      <DescriptionInput title="Description" placeholder="Add a comment" onChange={(e) => setDescription(e.target.value)} />

      <ActionContainer>
        <CancelLink onClick={previous}>{translate('Cancel')}</CancelLink>
      </ActionContainer>
      <ActionContainer>
        <AcceptLink onClick={handleAccept}>{translate('Apply Charge')}</AcceptLink>
      </ActionContainer>
    </div>
  ) : null;
};

export default AddCharge;
