import styled from 'styled-components';
import colors from 'resources/constants/colors';
import { Link } from 'react-router-dom';
import { Text } from 'components/Text';

export const SelectionContainer = styled.div`
  width: 100%;
  background-color: ${({ active }) => (active ? `rgba(${colors.acceptRGB}, 0.1)` : 'transparent')};
  display: flex;
  align-items: center;
`;

export const SelectionText = styled(Text)`
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;

  @media( min-width: 768px ){
    font-size: 14px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  flex: 1;
`;
