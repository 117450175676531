import React from "react";
import useI8n from "i18n/useI18n";
import IconButton from 'components/Buttons/IconButton';
import styled from 'styled-components';
import { PRIMARY } from "@etiquette-ui/colors";
import useDisputes from "state/disputes";

const Button = styled(IconButton)`
  margin-left: 15px;
  p {
    color: ${PRIMARY};
  }
`;


const UpdateData = () => {
  const [data, dispatcher] = useDisputes();

  const onUpdate = () => {
    dispatcher.fetchStart();
  };

  const { translate } = useI8n();

  return <Button onClick={onUpdate} icon={'update'} text={translate('Update Data')} loading={data.isFetching} />;
};

export default UpdateData;
