import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';
import { TABLE_MODES } from '../../utils';

const NoShow = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.NO_SHOW} structure={structure} title={'no_show_table_title'} htmlId={'no-show-table-container'} mode={TABLE_MODES.PAST}/>;
};

export default NoShow;
