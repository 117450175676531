import React, { useEffect, useState } from 'react';
import { BoldText } from 'components/Text';
import ActionContainer from './components/ActionContainer';
import { InputContainer, Icon, InputLabel } from 'components/Inputs';
import Selector from 'components/Selector';
import DescriptionIcon from 'resources/img/description-icon.svg';
import useUsers from 'state/users';
import useI18n from 'i18n/useI18n';
import { AcceptButton } from 'components/Buttons';
import UserTypes from 'resources/constants/userTypes';

const reasons = (translate) => [
  { id: 0, label: translate ? translate('Special Client') : 'Special Client'},
  { id: 1, label: translate ? translate('Owner approval') : 'Owner approval' },
  { id: 2, label: translate ? translate('Arrangement with Client') : 'Arrangement with Client' },
];

const ElectedNoCharge = ({ dispatcher, reservation, changeStep, currentStep = null }) => {
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState(0);
  const [reason, setReason] = useState(0);
  const [users, usersDispatcher] = useUsers();
  const { translate } = useI18n();

  useEffect(() => {
    usersDispatcher.fetchStart(UserTypes.MANAGERS);
  }, []);

  useEffect(() => {
    const list = users.managers.map((mngr) => ({ id: mngr.id, label: `${mngr.name} ${mngr.lastName}` }));
    if(list.length===0) return;
    setManager(list[0].id);
    setManagers(list);
  }, [users.managers]);

  const handleAccept = () => {
    dispatcher.updateLinkCharge({
      code: reservation.link.code,
      electedNoCharge: {
        id: manager,
        reason: reasons().find((rsn)=>rsn.id===+reason).label,
      }
    });
    changeStep(0);
  };

  return currentStep === 2 ? (
    <div>
      <BoldText>{translate('Manager approved no charge')}</BoldText>
      {managers.length > 0 && (
        <>
          <InputLabel>{translate('Manager')}</InputLabel>
          <InputContainer>
            <Icon src={DescriptionIcon} />
            <Selector width={'100%'} selected={manager} handleOptions={(e) => setManager(e.target.value)} options={managers} />
          </InputContainer>
        </>
      )}
      <InputLabel>{translate('Reason')}</InputLabel>
      <InputContainer>
        <Icon src={DescriptionIcon} />
        <Selector width={'100%'} selected={reason} handleOptions={(e) => setReason(e.target.value)} options={reasons(translate)} />
      </InputContainer>
      <ActionContainer>
        <AcceptButton onClick={handleAccept} text={translate('Save')} />
      </ActionContainer>
    </div>
  ) : null;
};

export default ElectedNoCharge;
