import React from 'react';
import structure from './structure';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';
import CustomTable from '../../components/Table';

const Cancelled = () => {
  return <CustomTable statusCode={LOCAL_STATUS_CODE.CONFIRMED} structure={structure} title={'confirmed_table_title'} htmlId={'confirmed-table-container'} />;
};

export default Cancelled;
