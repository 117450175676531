import { DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  isFetching: false,
  displayedDate: '',
  error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CHANGE_DISPLAYED_DATE: return { ...state, displayedDate: action.payload };
    case Types.FETCH_START: return { ...state, isFetching: true };
    case Types.FETCH_SUCCESS:
      const list = [...state.list];

      if(Array.isArray(action.payload)) {
        action.payload.forEach((date)=>{
          if(!list.includes(date)) {
            list.push(date);
          }
      });
      }
      

      return { ...state, list, isFetching: false, error: { ...DEFAULT_NO_ERROR } };
    case Types.FETCH_ERROR: return { ...state, isFetching: false, error: { timestamp: Date.now(), message: action.payload } };

    case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

    default:
      return state;
  }
};

export default reducer;
