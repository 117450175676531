import React from 'react';
import { H1 } from '@etiquette-ui/typography';
import logoPNG from 'resources/img/reservations.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logocontainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  h1 {
    color: white;
    cursor: pointer;
    padding-left: 12px;
    box-sizing: border-box;
    font-size: 1.5rem;
    font-weight: 600;
  }
  img {
    width: 40px;
    height: 40px;
  }

  @media( max-width: 1000px ){
    height: auto;

    h1 {
      color: black;
      font-size: 18px;
      padding-left: 0px
    } 
  }

  @media( max-width: 768px ){
    img {
      display: none;
    }
  }
`;

const Logo = () => {
  return (
    <Link to={'/'} style={{textDecoration: 'none'}}>
      <Logocontainer>
        <img src={logoPNG} alt={'reservations-logo'} />
        <H1>Reservations</H1>
      </Logocontainer>
    </Link>
  );
};

export default Logo;
