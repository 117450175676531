import { DEFAULT_NO_ERROR } from "state/constants";
import { NOMENCLATORS_FAMILIES } from "./constants";

export const cloneState = (state) => {
    const newState = {...state};
    Object.values(NOMENCLATORS_FAMILIES).forEach((family)=>{
        newState[family] = {...state[family]};
    });
    return newState;
}

export const resetNomenclatorsKeys = (state, keys, exceptions = []) => {
    Object.values(NOMENCLATORS_FAMILIES).forEach((family)=>{
        keys.forEach((key)=>{
            if(!exceptions.includes(family)) state[family][key] = false;
        });
    });
}

export const resetNomenclatorError = (state) => {
    state.error = { ...DEFAULT_NO_ERROR };
}