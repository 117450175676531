import Types from './types';
import { getToday } from 'utils/date';

const INITIAL_STATE = getToday();

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.CHANGE_DATE: return action.payload;

        default: return state;
    }
}

export default reducer;