import { LOCAL_STATUS_CODE } from "resources/constants/status";

const ES = {
    "Internal Server Error": "Error Interno del Servidor",
    "Please report this to the dev team.": "Favor de reportar esto al equipo de desarrollo.",
    "OK": "Ok",
    "Go Back": "Regresar",
    "Cancel": "Cancelar",

    "SLOW_CONNECTION":"Esto está tardando más de lo usual, por favor NO actualices la página para terminar de cargar la información.",

    "Reservations": "Reservaciones",
    "reservations": "reservaciones",
    "Search": "Buscar",
    "Expired": "Expirado",
    "Pending": "Pendiente",
    "Cancelled": "Cancelado",
    "Cancelled at link": "Cancelado en el link",
    "Table for": "Mesa para",
    "Add Hotel Tag": "Seleccionar hotel",
    "Add expiration time": "Alargar tiempo de expiración",
    "Add more expiration time": "Añadir más tiempo de expiración",
    "Added time": "Añadir tiempo",
    "Update customer link": "Actualizar link del cliente",
    "Copy": "Copiar",
    "Close": "Cerrar",
    "Update Data": "Actualizar información",
    "Logout": "Salir",
    "Generate customer link": "Generar link para el cliente",
    "Expires in": "Expira en",
    "mins.": "minutos",
    "seconds": "segundos",
    "Link expiration time": "Tiempo de validez del link",
    "Minutes": "Minutos",
    "Hours": "Horas",
    "Days": "Dias",
    "Select": "Selecciona",
    "Add": "Añadir",
    "ago": " ",
    "Add Concierge Name": "Añadir nombre del concierge",
    "hours": "horas",
    "Attended": "Asistió",
    "Confirmed with Card": "Confirmó desde el link",
    "Not Attended": "No Asistió",
    "Has the customer attended?": "Asisitió el cliente?",
    "Customer has not attended": "El cliente no asistió",
    "Customer has attended": "El cliente asistió",
    "Yes": "Si",
    "No": "No",
    "Change": "Cambiar",
    "Add Charge": "Añadir Cargo",
    "charged": "cobrado",
    "Reservation Type": "Tipo de Reservación",
    "Client Type": "Tipo de Cliente",
    "Edit": "Editar",
    "Wating confirmation": "Esperando confirmación",
    "Cancel in OT": "Cancelar en OpenTable",
    "Attend?": "¿Atendió?",
    "Call to Customer": "Llamar al cliente",
    "Create Link": "Crear Link",
    "Elected No Charge": "Elegió No Aplicar Cargo",

    "All Status": "Todos",
    [LOCAL_STATUS_CODE.CREATE]: "Nueva Reservación",
    [LOCAL_STATUS_CODE.WAITING]: "Esperando tarjeta",
    [LOCAL_STATUS_CODE.CANCELLED]: "Cancelada",
    [LOCAL_STATUS_CODE.EXPIRED]: "Link Expirado",
    [LOCAL_STATUS_CODE.CONFIRMED]: "Confirmado con Tarjeta",
    [LOCAL_STATUS_CODE.NO_SHOW]: "No Asistió",
    [LOCAL_STATUS_CODE.ATTENDED]: "Asistió",
    "Cancelled Reservation": "Reservación Cancelada",

    "Notifications": "Notificaciones",
    "Pending Charges": "Cargos Pendientes",
    "View by": "Ver por",
    "Filter by": "Filtrar por",
    "Back": "Regresar",
    "Reservation Summary": "Resumen de Reservaciones",
    "Export report": "Exportar Actividad",
    "Charges": "Cargos",
    "Apply All Charges": "Aplicar Todos los Cargos",

    "create_link_table_title": "Crea el link para que los clientes puedan completar su información.",
    "waiting_table_title": "Ver abajo para dar a clientes más tiempo para ingresar su tarjeta.",
    "expired_table_title": "1. Contacta al cliente para preguntar si necesitan más tiempo y reiniciar el timer.  2. Si no puedes contactarlos cancela la reservación en OpenTable.",
    "no_show_table_title": "Verifica con un gerente si debes aplicar un cargo, después aplica el cargo.",
    "confirmed_table_title": "15 minutos antes del tiempo de reservación, puedes marcar si el cliente asistió o no asistió.",
    "reservations pending to apply charge": " reservaciones pendientes para aplicar cargos.",

    "Action Item": "Acción",
    "Date": "Fecha",
    "Time": "Hora",
    "Date and Time": "Fecha y Hora",
    "Last, First Name": "Apellido, Nombre",
    "#ppl": "#prs",
    "Phone": "Teléfono",
    "Time to expiration": "Tiempo de expiración",
    "Extend": "Extender",
    "Update": "Actualizar",
    "View Details": "Ver Detalles",
    "Expired Ago": "Expiró hace",
    "Reset time or Cancel": "Reiniciar timer o Cancelar",
    "Reset time": "Reiniciar timer",
    "Call Customer": "Llamar al Cliente",
    "Cancel Reservation": "Cancelar Reservación",
    "Charge/Status": "Cargo/ Status",
    "Successful Charge": "Cargo Exitoso",
    "Charge Rejected": "Cargo Rechazado",
    "Charge Pending": "Cargo Pendiente",
    "Charge Completed": "Cargo Completado",
    "Not charged yet": "No hay cargo aún",
    "Charged x": "Se cargó %charge%",
    "Successful Cancellation": "Cancelación Exitosa",
    "Cancel on OpenTable": "Cancelar en OpenTable",
    "Does the client attend?": "¿El cliente asistió?",
    "Total": "Total",
    "Rejected": "Rechazado",
    "Collected": "Recolectado",
    "Status": "Estatus",
    "Last Attempt": "Último Intento",
    "Manager Approved No Charge": "Gerente Aprobó No Cargo",
    "Manager approved no charge": "Gerente abrobó no cargo",
    "Manager": "Gerente",
    "Hostess": "Hostess",
    "Host": "Host",
    "Reason": "Motivo",
    "Save": "Guardar",
    "Approved No Charge": "Aprobó No Cargo",
    "Add customer charge.": "Añadir cargo a cliente.",
    "Apply Charge": "Aplicar Cargo",
    "Created by": "Creado por",
    "Last Change": "Último Cambio",
    "Time to cancel": "Tiempo para cancelar",

    "succeeded": "Exitoso",
    "requires_action": "Acción Necesaria",
    "processing": "Procesando",
    "payment_failed": "Pago No Exitoso",
    "canceled": "Cancelado",

    "warning_needs_response":"Alerta que Necesita Respuesta",
    "warning_under_review":"Alerta en Revisión",
    "warning_closed":"Alerta Cerrada",
    "needs_response":"Necesita Respuesta",
    "under_review":"En Revisión",
    "charge_refunded":"Cargo Devuelto",
    "won":"Ganada",
    "lost":"Perdida",

    "day": "Día",
    "month": "Mes",
    "range": "Rango",

    "Disputes": "Disputas",
    "Description": "Descripción",
    "Email": "Email",
    "Go To Link": "Ir al Link",
    "Copy Data": "Copiar Datos",
    "PDF File": "Archivo PDF",

    "Special Client": "Cliente Especial",
    "Owner approval": "Aprobado por el Dueño",
    "Arrangement with Client": "Arreglo con Cliente",

    "Client has cancelled.": "El cliente %last_name%, %first_name% canceló.",
    "Cancelled reservation": "Reservación cancelada",
    "Confirmed reservation": "Reservación confirmada",
    "Updated reservation": "Reservación actualizada",
    "Go to OpenTable to cancel.": "Ir a OpenTable para cancelar.",
    "Client has confirmed.": "El cliente confirmó asistencia.",

    "CHARGE_SUCCEEDED_TITLE": "Cargo realizado",
    "CHARGE_SUCCEEDED_MSG": "Se acaba de realizar un cargo por valor de %amount%",
    "CHARGE_REQUERIES_ACTION_TITLE": "Cargo necesita atención",
    "CHARGE_REQUERIES_ACTION_MSG": "El cargo no pasó, necesita atención",
    "CHARGE_PROCESSING_TITLE": "Cargo en proceso",
    "CHARGE_PROCESSING_MSG": "Cargo esta siendo procesado.",
    "CHARGE_FAILED_TITLE": "Cargo no pasó",
    "CHARGE_FAILED_MSG": 'Falló el cargo con el error: " %errorMsg% "',
    "CHARGE_CANCELLED_TITLE": "Cargo cancelado",
    "CHARGE_CANCELEED_MSG": "El cargo fue cancelado por Stripe.",
    "CHARGE_CREATE_TITLE": "Cargo creado",
    "CHARGE_CREATE_MSG": "Se ha creado un nuevo cargo en la plataforma de pagos.",

    "CHARGE_WARNING_NEEDS_RESPONSE_TITLE": "Cargo necesita atención",
    "CHARGE_WARNING_NEEDS_RESPONSE_MSG": "El cargo realizado no se completo, necesita acciones por parte del equipo.",
    "CHARGE_WARNING_UNDER_REVIEW_TITLE": 'Disputa actualizada a "En Revisión"',
    "CHARGE_WARNING_CLOSED_TITLE": "Disputa cerrada",
    "CHARGE_CHARGE_REFUNDED_TITLE": "Cargo devuelto a raíz de disputa",
    "CHARGE_WON_TITLE": "Disputa GANADA",
    "CHARGE_LOST_TITLE": "Disputa Perdida",
    "CHARGE_NO_ACTION_MSG": "NO se require acción por parte del equipo.",

    "RESERVATION_CREATED_TITLE": "Reservación creada",
    "RESERVATION_CREATED_MSG": "Nueva reservación creada a nombre de  %firstName% %lastName%.",
    "RESERVATION_CANCELLED_TITLE": "Reservación cancelada",
    "RESERVATION_CANCELLED_MSG": "Cliente %firstName% %lastName% canceló su reserva. Por favor, cancele la reservación en OpenTable.",
    "RESERVATION_UPDATE_TITLE": "Reservación actualizada",
    "RESERVATION_UPDATE_MSG": "La Reservación fue actualizada.",
    "RESERVATION_CHECKIN_TITLE": "Reservación confirmada",
    "RESERVATION_CHECKIN_MSG": "Cliente  %firstName% %lastName% confirmó su reservación. ",
    "VIEW_RESERVATION": "Ver reservación",
    "View Disputes": "Ver Disputas",

    "Reservations pending to cancel": "Reservations pendientes de cancelación",
    "Please cancel all these reservations in Opentable.": "Por favor cancela todas estas reservaciones en Opentable.",
    "The link has been copied.": "El link ha sido copiado.",

    "Unauthorized": "No autorizado",
    "You don't have access to this app": "No tienes acceso a esta aplicación",

    "Link Expired": "Link Expirado",
    "Review expired links and contact customer if required": "Revisa links expirados y contacta al cliente de ser necesario",
    "Whats app message": "En el siguiente link puede revisar las políticas para reservar y confirmar la reserva que nos ha solicitado ingresando los datos requeridos. El enlace dejará de estar activo en %time%\n" +
      "Cualquier duda o aclaración estamos para apoyarle.\n" +
      "- Quince Rooftop.\n" +
      "%linkURL%",
    "Generic error": "Hubo un error en la solicitud, porfavor refresca la página"
}

export default ES;
