import { parsePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

export const getFormattedName = (guest) => {
  return guest ? `${guest.lastName ?? ''}, ${guest.firstName ?? ''}` : '';
};

export const getFormattedLastName = (guest) => {
  return guest ? `${guest.lastName}` : 'None';
};

export const getFormattedPhone = (guest) => {
  if (!guest?.phone) return '';
  const parsedNumber = parsePhoneNumber(guest.phone);
  return parsedNumber ? formatPhoneNumberIntl(guest.phone) : '';
};

export const getFormattedTable = (reservation, text) => (reservation.partySize ? `${text} ${reservation.partySize}` : '');

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',', currencySymbol = '$') => {
  try {
    const negativeSign = amount < 0 ? '-' : '';
    const amountFixed = Math.abs(amount).toFixed(decimalCount);
    const amountRounded = parseInt(amountFixed);
    const amountDecimal = parseInt((amountFixed - amountRounded) * 100).toString();

    const amountLength = amountRounded.toString().length;
    const thousandPartsCount = Math.floor(amountLength / 3);
    const thousandsPartsModulus = amountLength % 3;

    const parts = [];
    for (let i = 1; i <= thousandPartsCount; i++) {
      parts.unshift(amountRounded.toString().substring(amountLength - i * 3, amountLength - i * 3 + 3));
    }
    parts.unshift(amountRounded.toString().substring(0, thousandsPartsModulus));

    return negativeSign + currencySymbol + (amountLength <= 3 ? amountRounded : parts.join(thousands)) + decimal + (amountDecimal < 10 ? `0${amountDecimal}` : amountDecimal);
  } catch (e) {
    console.error(e);
  }
};
