const DEFAULT_NOTIFICATION = ({ type, isRead }) => ({
    title: 'NOTIFICATION',
    info: type,
    message: '',
    isRead,
});


export const viewReservation = (code, text, showReservationDialog) => {
    if (!code) return { text: "", callback: () => { } }
    return {
        text,
        callback: () => showReservationDialog(code)
    }
}

export function extractSubAction(typeString) {
    return typeString.substr(typeString.indexOf("_") + 1, typeString.length)
}

export default DEFAULT_NOTIFICATION;