import React from 'react';
import styled from 'styled-components';
import { TEXT, INPUTS } from '@etiquette-ui/colors';
import useI18n from 'i18n/useI18n';
import { Text } from 'components/Text';
import UpdateData from './UpdateData';

const Header = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 1px ${INPUTS};
  align-items: center;
  padding: 0 15px;
  padding-bottom: 10px;
  justify-content: space-between;
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 10px auto;
    color: ${TEXT};
  }

  @media( min-width: 1001px ){
    padding: 0 36px;
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  margin: 0px auto;
  
  @media( min-width: 1001px ){
    margin-bottom: 15px;
  }
`;

const CardHeader = () => {
  const { translate } = useI18n();

  return (
    <Header>
      <Title>{translate('Disputes')}</Title>
      <UpdateData />
    </Header>
  );
};

export default CardHeader;
