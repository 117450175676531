import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text';
import { RIGHT, WRONG } from '@etiquette-ui/colors';

const TitleContainer = styled.div`
  display: flex;
  align-items: start;
  padding-left: 20px;

  &::before {
    content: '';
    position: absolute;
    margin-top: 5px;
    margin-left: -15px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ positive }) => (positive ? RIGHT : WRONG)};
  }
`;

const Title = styled(Text)`
  font-size: 16px;
  transform: scale(1);
  padding-right: 5px;
  transition-duration: 0.1s;
  transition-property: scale;
  &.blink {
    transition-duration: 0.1s;
    transform: scale(1.05);
  }
  @media (min-width: 768px) {
    font-weight: 500;
  }
`;

const Count = styled.span`
  margin-left: 5px;
  &.bold {
    font-size: 14px;
    font-weight: 700;
  }
`;

const TitleComponent = ({ hasAlert, text, count, positive }) => {
    const [blink, setBlink] = useState(false);

    useEffect(() => {
    if (hasAlert) {
        setTimeout(() => {
        setBlink(!blink);
        }, 1000);
    }
    }, [blink]);

    return (
    <TitleContainer positive={positive}>
        <Title className={blink && hasAlert ? 'blink' : ''} dangerouslySetInnerHTML={{ __html: text }} />
        <Count className={count > 0 ? 'bold' : ''}>({count})</Count>
    </TitleContainer>
    );
};

export default TitleComponent;