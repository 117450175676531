import DEFAULT_NOTIFICATION, { viewReservation } from "./default";

const process = (notification, translate, showReservationDialog) => {

    const code = notification?.payload?.reservation?.code;
    const text = translate("VIEW_RESERVATION");

    switch (notification.type.split('_')[1]) {
        case 'CREATED': {
            return {
                title: translate('RESERVATION_CREATED_TITLE'),
                message: translate('RESERVATION_CREATED_MSG', {
                    "firstName": notification.payload.client?.firstName ?? '',
                    "lastName": notification.payload.client?.lastName ?? '',
                }),
                action: viewReservation(code, text, showReservationDialog),
                isRead: notification.isRead,
            };
        }
        case 'CANCELLED':
            return {
                title: translate('RESERVATION_CANCELLED_TITLE'),
                message: translate('RESERVATION_CANCELLED_MSG', {
                    "firstName": notification.payload.client?.firstName ?? '',
                    "lastName": notification.payload.client?.lastName ?? '',
                }),
                action: viewReservation(code, text, showReservationDialog),
                isRead: notification.isRead,
            };
        case 'UPDATED':
            return {
                title: translate('RESERVATION_UPDATE_TITLE'),
                message: translate('RESERVATION_UPDATE_MSG'),
                action: viewReservation(code, text, showReservationDialog),
                isRead: notification.isRead,
            };
        case 'CHECKIN':
            return {
                title: translate('RESERVATION_CHECKIN_TITLE'),
                message: translate('RESERVATION_CHECKIN_MSG', {
                    "firstName": notification.payload.client?.firstName ?? '',
                    "lastName": notification.payload.client?.lastName ?? '',
                }),
                action: viewReservation(code, text, showReservationDialog),
                isRead: notification.isRead,
            };
        default:
            return DEFAULT_NOTIFICATION(notification);
    }


}




export default process;