import React from 'react'
import styled from 'styled-components';

export const TableContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 87%;
  width: 100vw;
  padding: 0 15px;
  margin-top: 15px;
  overflow: scroll;
  position: relative;

  @media( min-width: 1000px ){
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media( min-height: 769px ){
    height: 90%;
  }
`;
