import { useContext, useState, useEffect } from 'react'
import { I18nContext } from './provider';
import {
    secondsFromMiliseconds,
    hoursFromInteger,
    hoursToSeconds,
    minutesFromInteger,
} from 'utils/time'

import { getDisplayDate, ALT_DISPLAY_DATE_FORMAT } from 'utils/date';


const useI18n = () => {
    const { translate, selectedLang, setSelectedLang } = useContext(I18nContext);
    const [expirations, setExpirations] = useState([]);

    useEffect(() => {
        setExpirations([
            { id: 0, label: translate('Minutes'), multiplier: 60 },
            { id: 1, label: translate('Hours'), multiplier: 3_600 },
            { id: 2, label: translate('Days'), multiplier: 86_400 },
        ])

    }, [selectedLang])


    function translateSeconds(secondsInteger) {
        if (secondsInteger === false || secondsInteger < 0) return "";

        const counter = secondsFromMiliseconds(secondsInteger);
        const hours = hoursFromInteger(counter);
        const secondsInHours = hoursToSeconds(hours)
        const minutes = minutesFromInteger(counter - secondsInHours);

        return hours ? translate('hours') : (minutes ? translate('mins.') : translate('seconds'));
    }

    const formatDate = (date, format = ALT_DISPLAY_DATE_FORMAT) => getDisplayDate(date, format, selectedLang);

    return { translate, selectedLang, setSelectedLang, translateSeconds, expirations , formatDate };
}


export default useI18n;