import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Tabs from './components/Tabs';
import routes from './routes';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router';
import Create from './pages/Create';
import Waiting from './pages/Waiting';
import Expired from './pages/Expired';
import NoShow from './pages/NoShow';
import Cancelled from './pages/Cancelled';
import Confirmed from './pages/Confirmed';
import Attended from './pages/Attended';
import useReservations from 'state/reservations';
import TabsSelector from './components/TabsSelector';
import { SECONDARY_DARK } from '@etiquette-ui/colors';

const SectionContainer = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
`;

const TabsContainer =  styled.div`
  width: 100%;
  padding: 0px;
  display: none;

  @media( min-width: 1025px ){
    display: block;
  }
`;

const TabsSelectorContainer = styled(TabsContainer)`
  display: block;
  margin-top: 7px;
  padding-top: 7px;
  padding-bottom: 8px;
  background-color: ${SECONDARY_DARK};

  @media( min-width: 1024px ){
    display: none;
  }
`;

const Summary = ({ location }) => {
  const { path, url } = useRouteMatch();
  const [reservations] = useReservations();
  const [currentItem, setCurrentItem] = useState({});
  let routesArray = [];

  useEffect(()=>{
    routesArray = routes(url, reservations.dates);
    console.log(routesArray);
  },[]);

  

  useEffect(() => {
    
    const itemFiltered = routesArray.filter(({path}) => path === location.pathname);
    setCurrentItem(itemFiltered[0]);
    console.log(currentItem);
  }, [location.pathname]);

  return (
    <SectionContainer>
      <TabsContainer>
        <Tabs routes={routes(url, reservations.dates)} pathIndex={3} current={location.pathname} />
      </TabsContainer>
      <TabsSelectorContainer>
        <TabsSelector routes={routes(url, reservations.dates)} pathIndex={3} current={location.pathname} />
      </TabsSelectorContainer>
      
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/create`} />
        </Route>
        <Route path={`${path}/create`} component={Create} />
        <Route path={`${path}/waiting`} component={Waiting} />
        <Route path={`${path}/expired`} component={Expired} />
        <Route path={`${path}/no-show`} component={NoShow} />
        <Route path={`${path}/cancelled`} component={Cancelled} />
        <Route path={`${path}/confirmed`} component={Confirmed} />
        <Route path={`${path}/attended`} component={Attended} />
      </Switch>
    </SectionContainer>
  );
};

export default Summary;
