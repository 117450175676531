import { LOCAL_STATUS_CODE, OPENTABLE_STATUS_CODE } from 'resources/constants/status';
import { getToday } from 'utils/date';
import { TABLE_MODES } from './utils';

const getData = (dates, status, mode) => {
  const list =
    Object.keys(dates)
      .filter((key) => {
        const date = key.split('-').join('');
        const today = getToday().split('-').join('');
        if (mode === TABLE_MODES.PAST) return date <= today;
        return date >= today;
      })
      .map((key) => dates[key])
      .reduce((curr, acc) => [...curr, ...acc], []) ?? [];
  return { title: status, count: list.filter(({ localStatusCode }) => localStatusCode === status).filter(({ localStatusCode, link }) => localStatusCode === LOCAL_STATUS_CODE.CREATE || !!link).length };
};

const needsAlert = (dates) => {
  const list = Object.keys(dates)
    .filter((d) => {
      const date = d.split('-').join('');
      const today = getToday().split('-').join('');
      return date >= today;
    })
    .map((key) => dates[key])
    .reduce((acc, curr) => acc.concat(curr), [])
    .filter(({ localStatusCode }) => localStatusCode === LOCAL_STATUS_CODE.CANCELLED)
    .filter(({ state }) => state !== OPENTABLE_STATUS_CODE.CANCELLED);

  return list.length > 0;
};

const routes = (url, dates) => {
  return [
    { path: `${url}/create`, ...getData(dates, LOCAL_STATUS_CODE.CREATE, TABLE_MODES.FUTURE), title: 'Create Link', flex: 2 },
    { path: `${url}/waiting`, ...getData(dates, LOCAL_STATUS_CODE.WAITING, TABLE_MODES.FUTURE), flex: 3 },
    { path: `${url}/expired`, ...getData(dates, LOCAL_STATUS_CODE.EXPIRED, TABLE_MODES.FUTURE), flex: 2 },
    { path: `${url}/no-show`, ...getData(dates, LOCAL_STATUS_CODE.NO_SHOW, TABLE_MODES.PAST), flex: 2 },
    {
      path: `${url}/cancelled`,
      ...getData(dates, LOCAL_STATUS_CODE.CANCELLED, TABLE_MODES.FUTURE),
      title: 'Cancelled Reservation',
      flex: 4,
      hasAlert: needsAlert(dates),
    },
    { path: `${url}/confirmed`, ...getData(dates, LOCAL_STATUS_CODE.CONFIRMED, TABLE_MODES.FUTURE), flex: 3, positive: true },
    { path: `${url}/attended`, ...getData(dates, LOCAL_STATUS_CODE.ATTENDED, TABLE_MODES.PAST), flex: 2, positive: true },
  ];
};

export default routes;
