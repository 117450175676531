import styled from 'styled-components';
import {PRIMARY, INPUTS} from '@etiquette-ui/colors';
import Select from 'react-select';
import { Text } from 'components/Text';
import useI18n from 'i18n/useI18n';
export const Selector = styled(Select)`
  height: 100%;
  width: 100%;
  padding-top: 5px;
  .react-select__control {
    background-color: ${INPUTS};
    outline: none !important;
    border: none !important;
    box-shadow: none;
    height: 100%;
    min-height: unset;
    &:focus {
      outline: none !important;
      border: none !important;
    }
    &:hover {
      outline: none !important;
      border: none !important;
    }
  }
  .react-select__value-container {
    padding: 0;
    padding-left: 5px;
  }
  .react-select__single-value {
    margin: 0;
  }
  .react-select__indicator {
    padding: 2px;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    position: absolute;
  }
`;

export const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  color: ${PRIMARY};
  pointer-events: none;
  left: 0;
  top: 5px;
  transition: top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1), font-size 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
`;

export const InputContainer = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
  &.up {
    padding-top: 0px;
    label {
      top: -2px;
      font-size: 0.75rem;
    }
  }
  @media( min-width: 1001px ){
    margin-left: 15px;
  }
`;

const OptionContainer = styled.div`
  display: inline-flex;
`;

const OptionComponent = ({ data }) => {
  const { translate } = useI18n();
  return (
    <OptionContainer>
      <Text style={{fontSize: '0.875rem'}}>{`${translate(data.label)} (${data.count})`}</Text>
    </OptionContainer>
  );
};

export const SelectFormatOptionLabel = (data) => {
  return <OptionComponent data={data} />;
};
