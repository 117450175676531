import React from 'react';
import BaseDialog from 'alert/components/BaseDialog';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import Cancelled from './Cancelled';

const Dialog = styled(BaseDialog)`
  .MuiPaper-root {
    max-width: 80vw;
    max-height: 80vh;
    height: 80vh;
  }
  .dialog-content-container {
    max-height: 90%;
    padding: 15px 5px;
    overflow: scroll;
  }
`;

const PendingReservations = ({ id, open, onSubmit, onClose }) => {
  const { translate } = useI18n();

  return <Dialog open={open} onClose={onClose} dismissible={false} header={translate('Reservations pending to cancel')}>
    <Cancelled />
  </Dialog>;
};

export default PendingReservations;
