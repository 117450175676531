import Axios from "axios";
import Authorization from './auth';
import { executeCall } from './utils';

const notification = (domain, resource) => {
  const url = `${domain}/${resource}`;

  const headerGetAll = {
    ...Authorization,
    page: 0,
    size: 100
  }

  return {
    getAll: () => executeCall(() => Axios.get(url + `/`, { headers: headerGetAll })),
    readed: () => executeCall(() => Axios.get(url + '/readed', { headers: Authorization })),
  };
}

export default notification;