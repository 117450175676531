import React, { useEffect, useState } from 'react';
import { authAPI } from 'resources/api';
import { ACCESS_USER_TYPES } from 'resources/constants/config';

const AccessContext = React.createContext();

const AccessProvider = ({ children }) => {
  const [accessList, setAccessList] = useState([]);
  const [accessByGroup, setAccessByGroup] = useState({});
  const [groupsIds, setGroupsIds] = useState([]);
  const [userType, setUserType] = useState('');
  const auth = localStorage.getItem("auth");

  useEffect(()=>{
    const parsedAuth = JSON.parse(auth);
    setAccessList(parsedAuth !== null ? parsedAuth.access : []);
    setUserType(parsedAuth !== null ? parsedAuth.ofType : '');
    setGroupsIds(parsedAuth !== null ? parsedAuth.groups : []);
  }, [auth]);

  const loadAccess = async (groupsIds) => {
    const access = {};
    for(const id of groupsIds) {
      const res = await authAPI.getAll(id);
      access[+id] = res;
    }
    setAccessByGroup(access);
  }

  useEffect(()=>{
    loadAccess(groupsIds);
  }, [groupsIds]);

  const hasAccess = (access, type) => {
    return userType === ACCESS_USER_TYPES.ROOT || accessList.includes(access) || userType === type;
  }

  const hasAccessInGroup = (groupId, access, type) => {
    return userType === ACCESS_USER_TYPES.ROOT || (accessByGroup[+groupId] ?? []).includes(access) || userType === type;
  }

  return <AccessContext.Provider value={{hasAccess, hasAccessInGroup}}>{children}</AccessContext.Provider>;
};

export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;