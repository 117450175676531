import React, { useEffect } from 'react';
import {WRONG, WRONG_LIGHT} from '@etiquette-ui/colors';
import TimeInfo from './TimeInfo';
import { Body, ActionsContainer } from './styles';
import { needsLink, isExpired, isCancelled } from 'utils/status';
import { AltTransparentButton } from 'components/Buttons';
import CommonInfo from './CommonInfo';
import useReservations from 'state/reservations';
import useI8n from "i18n/useI18n";
import useNomenclators from 'state/nomenclators';


const InfoStep = ({ id, reservation, onClose, next, currentStep=null, reference, actionActive=true }) => {
  const [reservationsData] = useReservations();
  const { translate } = useI8n();
  const [, dispatcher] = useNomenclators();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  return currentStep===0 || currentStep===null ? (
    <Body ref={reference}>
      {reservation && <CommonInfo reservation={reservation} />}
      {reservation && !needsLink(reservation) && !isCancelled(reservation) && <TimeInfo id={id} reservation={reservation} onClose={onClose} />}
      {!reservationsData.isFetching && reservation && (needsLink(reservation) || isExpired(reservation)) && !isCancelled(reservation) && (
        <ActionsContainer>
          <AltTransparentButton onClick={actionActive ? next : ()=>{}} disabled={!actionActive} style={{ maxWidth: '238px' }}>
            {needsLink(reservation) ? translate('Generate customer link') : translate('Add expiration time')}
          </AltTransparentButton>
          <AltTransparentButton onClick={onClose} style={{ maxWidth: '238px', marginTop: '10px' }} color={WRONG} backgroundColor={WRONG_LIGHT}>
            {translate("Close")}
          </AltTransparentButton>
        </ActionsContainer>
      )}
    </Body>
  ) : null;
};

export default InfoStep;
