let authString = "";


export function getToken() {
  const retrievedToken = retrieveToken();

  const result = retrievedToken ? retrievedToken : false;

  return result;
}

function retrieveToken() {
  if (authString === "") {
    const auth = localStorage.getItem("auth");
    const parsedAuth = JSON.parse(auth);

    authString = parsedAuth !== null ? parsedAuth.token : "";
  }

  return authString
}

export default {
  Authorization: getToken(),
};