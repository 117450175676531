const TYPE = `[HIGHLIGHTED]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    CHANGE_DISPLAYED_DATE: `${TYPE} CHANGE_DISPLAYED_DATE`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;