import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { Text } from 'components/Text';
import ExportButton from './ExportButton';
import DateRange from '../DateRange';
import { TEXT, WRONG, DISABLED_BUTTON } from "@etiquette-ui/colors";

const Header = styled.div`
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  h1 {
    display: none;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 10px auto;
    color: ${TEXT};
  }

  @media( min-width: 1001px ){
    padding: 15px 36px;
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  margin: auto;
  display: none;

  @media( min-width: 1001px ){
    display: block;
  }
`;

const RangeFilterContainer = styled.div`
  max-width: 60%;
  flex: 0 0 60%;

  @media( min-width: 1001px ){
    position: absolute;
    top: -70px;
    left: 0px;

    max-width: 40%;
    flex: 0 0 40%;
  }
`;

const BannerInfo = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  border-radius: 0px 0px 12px 12px;
  color: ${WRONG};
  background-color: ${DISABLED_BUTTON};

  @media( min-width: 1001px ){
    position: absolute;
    top: -60px;
    left: 40%;
    max-width: 50%;
    flex: 0 0 50%;
    max-width: auto;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    background-color: transparent;
    color: white;
  }
`;

const CardHeader = ( {pendingChargesCounter} ) => {
  const { translate } = useI18n();



  return (
    <Header>
      <RangeFilterContainer>
        <DateRange />
      </RangeFilterContainer>
      <Title>{translate('Charges')}</Title>
      <ExportButton />
      <BannerInfo>
        {pendingChargesCounter + translate('reservations pending to apply charge')}
      </BannerInfo>
    </Header>
  );
};

export default CardHeader;
