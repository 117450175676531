import React from "react";

const Errors = ({ errors }) => (
  <ul style={{ color: "red" }}>
    {errors.map((error, i) => (
      <li key={i}>{error}</li>
    ))}
  </ul>
);

export default Errors;
