const TYPE = `[PENDING CHARGES]`;

const TYPES = {
    CHANGE_DATE: `${TYPE} CHANGE DATE`,
    UPDATE_DATE: `${TYPE} UPDATE DATE`,

    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    UPDATE_ALL: `${TYPE} UPDATE ALL`,

    CHANGE_DATE_TYPE: `${TYPE} CHANGE DATE TYPE`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}

export default TYPES;