import Logger from '@appsinti/logger';
import { APP_ID } from 'resources/constants/config';
import {getUserId} from 'utils/auth';

export async function executeCall(callback) {
    try {
        const response = await callback();
        return response.data;
    } catch (error) {
        const httpError = Logger.createError('general');
        httpError
            .setErrorObject(error)
            .setAppId(APP_ID)
            .setUserId(getUserId())
            .setTags(['frontend','reservations','http-error','admin'])
            .send();
    }

}
