import DEFAULT_NOTIFICATION from "./default";
import reservation from "./reservation";
import charge from './charge';
import dispute from "./dispute";

const process = (notification, translate, showReservationDialog, goToLink) => {
    const type = notification.type.split('_')[0];

    switch (type) {
        case 'RESERVATION': return reservation(notification, translate, showReservationDialog);
        case 'CHARGE': return charge(notification, translate, showReservationDialog);
        case 'DISPUTE':  return dispute(notification, translate, goToLink);
        default: return DEFAULT_NOTIFICATION(notification);
    }
}

export default process;